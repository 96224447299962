import { useAppContext } from '@contexts/RuntimeConfigProvider'
import Script from 'next/script'
import React from 'react'

interface Props {
	nonce: string
}

const SCRIPT_GTM = 'script-gtm'

export const SocialSciptsHead: React.FC<Props> = ({ nonce }) => {
	const { publicRuntimeConfig } = useAppContext()

	return (
		publicRuntimeConfig?.NEXT_PUBLIC_GTM_CODE && (
			<Script
				id={SCRIPT_GTM}
				data-nonce={nonce}
				nonce={nonce}
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
				(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
				new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
				'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
				n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
				})(window,document,'script','dataLayer','${publicRuntimeConfig.NEXT_PUBLIC_GTM_CODE}');
			  `
				}}
			/>
		)
	)
}

export const SocialSciptsBody: React.FC = () => {
	// DO NOT REPLACE THIS 'NEXT_PUBLIC_GTM_CODE' LINE
	// eslint-disable-next-line dot-notation, prefer-destructuring, @typescript-eslint/dot-notation
	const NEXT_PUBLIC_GTM_CODE = process.env['NEXT_PUBLIC_GTM_CODE']
	return (
		NEXT_PUBLIC_GTM_CODE && (
			<noscript>
				<iframe
					title="gtm"
					src={`https://www.googletagmanager.com/ns.html?id=${NEXT_PUBLIC_GTM_CODE}`}
					height="0"
					width="0"
					style={{ display: 'none', visibility: 'hidden' }}
				/>
			</noscript>
		)
	)
}
