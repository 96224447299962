import React, { FC, forwardRef } from 'react'

import { Icon, theme } from '@damen/ui'

import { CloseButtonElement } from './styles'

const CloseButton: FC = forwardRef<HTMLButtonElement, null>(
	(props: Record<any, any> | null, ref) => (
		<CloseButtonElement type="button" ref={ref} {...props}>
			<Icon.CloseThin height={16} width={16} fill={theme.colors.marine} />
		</CloseButtonElement>
	)
)

export default CloseButton
