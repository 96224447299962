import styled, { css } from 'styled-components'

import { HEADER } from '@src/constants/zIndexes'

interface Props {
	color: 'white' | 'transparent' | 'blue' | 'marineBlack'
	lockNavigation: boolean
}

export const Wrapper = styled.header<Props>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: background 0.2s;
	background-color: transparent;
	position: relative;
	z-index: 4;

	${({ lockNavigation = false }) =>
		lockNavigation &&
		css`
			z-index: ${HEADER};
			position: fixed;
			top: 0;
			width: 100%;

			@media ${({ theme }) => theme.legacyMediaQueries.md} {
				width: 100%;
			}
		`}

	${({ color, theme }) =>
		color !== 'transparent' &&
		css`
			background-color: ${(color === 'blue' && theme.colors.blue) ||
			(color === 'white' && theme.colors.white) ||
			(color === 'marineBlack' && theme.colors.marineBlack)};
		`}

	&,
	a {
		color: ${({ color, theme }) =>
			color === 'blue' ? theme.colors.white : theme.colors.marineBlack};
	}

	a {
		transition: color 0.2s;
	}
`

export const Left = styled.div`
	display: flex;
	align-items: center;

	@media ${({ theme }) => theme.legacyMediaQueries.mdMax} {
		flex-wrap: wrap;
		width: 100%;
	}
`
