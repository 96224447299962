import { useLocale } from '@hooks/useLocale'
import {
	GetMySettingsDocument,
	useEditMyUserAccountPreferencesMutation,
	useGetMyNotificationSettingsQuery
} from '@graphql/graphql'
import { mapLanguage } from '@utils/mapLanguages'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const useLanguageRedirect = () => {
	const [hasVisited, setHasVisited] = useState(false)

	// Contexts
	const { locale } = useLocale()

	const { data, loading, error } = useGetMyNotificationSettingsQuery({
		variables: {
			language: locale
		},
		skip: hasVisited
	})

	const [editMyUserAccountPreferences] =
		useEditMyUserAccountPreferencesMutation({
			refetchQueries: [GetMySettingsDocument]
		})

	const router = useRouter()

	useEffect(() => {
		if (hasVisited) {
			return
		}

		if (loading) {
			return
		}

		if (error) {
			return
		}

		const preferredLanguage =
			data?.myUserAccount?.notificationSettings?.preferredLanguage?.id

		if (!preferredLanguage) {
			const preferences = data.myUserAccount.notificationSettings
			editMyUserAccountPreferences({
				variables: {
					request: {
						caseCreatedNotification: preferences.createCase,
						caseClosedNotification:
							preferences.sendCaseClosureEmail,
						caseCommentCreatedNotification:
							preferences.createCaseComment,
						language: mapLanguage(locale)
					}
				}
			})
		}

		setHasVisited(true)

		if (router.locale !== preferredLanguage) {
			router.push(router.asPath, router.asPath, {
				locale: preferredLanguage
			})
		}
	}, [
		data,
		loading,
		error,
		router,
		locale,
		editMyUserAccountPreferences,
		hasVisited
	])
}

export default useLanguageRedirect
