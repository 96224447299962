import React from 'react'
import Link from 'next/link'
import { useHeaderContext } from '@contexts/HeaderContext'
import SkeletonLoader from '@components/UI/SkeletonLoader'
import { Icon, theme } from '@damen/ui'
import { signIn } from 'next-auth/react'
import { getMappedLinkHref } from '@utils/linkResolver'
import { hexToRgb } from '@utils/helper'
import { NavigationProps } from './types'
import { ListItem, StyledButton } from './styles'

const whiteAlpha50 = hexToRgb(theme.colors.white, {
	alpha: 0.5,
	format: 'css'
})

const getIcon = (iconName: string) => {
	switch (iconName) {
		case 'person':
			return (
				<Icon.UserCircle
					fill={`${whiteAlpha50}`}
					width={16}
					height={16}
				/>
			)

		case 'gear':
			return <Icon.Cog fill={`${whiteAlpha50}`} width={16} height={16} />

		case 'logout':
			return (
				<Icon.SignOut fill={`${whiteAlpha50}`} width={16} height={16} />
			)

		default:
			return null
	}
}

const Navigation = ({ data, loading, session }: NavigationProps) => {
	const { menuToggle } = useHeaderContext()

	if (loading) {
		const menuArray = [1, 2, 3]
		return (
			<ul>
				{menuArray.map((item) => (
					<li key={item}>
						<SkeletonLoader />
					</li>
				))}
			</ul>
		)
	}

	if (data) {
		const serviceMenuItems = data?.menuItems?.serviceMenu

		return (
			<nav>
				<ul>
					{serviceMenuItems &&
						serviceMenuItems.map((item) => {
							if (item.type === 'logout') {
								return (
									<ListItem key={`page__${item.title}`}>
										{getIcon(item.icon)}
										{session ? (
											<StyledButton
												onClick={() => {
													window.location.href =
														process.env.NEXT_PUBLIC_ENDPOINT_LOGOUT
												}}
											>
												{item.loggedInTitle}
											</StyledButton>
										) : (
											<StyledButton
												onClick={() =>
													signIn('iwelcome')
												}
											>
												{item.title}
											</StyledButton>
										)}
									</ListItem>
								)
							}

							return (
								<ListItem key={`page__${item.title}`}>
									{getIcon(item.icon)}
									<Link
										href={getMappedLinkHref(item.link.url)}
										onClick={menuToggle}
									>
										{item.title}
									</Link>
								</ListItem>
							)
						})}
				</ul>
			</nav>
		)
	}

	return <></>
}

export default Navigation
