import React, { FC } from 'react'
import { Field } from 'formik'

import Textarea from '@damen/ui/lib/cjs/components/Form/Textarea'

import { FieldTextareaProps } from './types'

const FieldInput: FC<FieldTextareaProps> = ({
	label,
	name,
	hideLabel,
	placeholder,
	readOnly = false,
	showError = true,
	setFieldValue
}) => (
	<Field name={name}>
		{({ field, form: { errors } }) => (
			<Textarea
				error={
					showError &&
					errors[name] !== '' &&
					errors[name] !== undefined
						? `${label} ${errors[name]}`
						: ''
				}
				readOnly={readOnly}
				label={hideLabel ? '' : label}
				name={name}
				value={field.value}
				touched
				placeholder={placeholder}
				onChange={(event) => {
					setFieldValue(name, event.target.value)
				}}
			/>
		)}
	</Field>
)

export default FieldInput
