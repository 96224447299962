import styled from 'styled-components'

import { theme } from '@damen/ui'

export const CloseButtonElement = styled.button`
	cursor: pointer;
	border: none;
	background-color: transparent;
	padding: 8px 11px;
	position: relative;

	&::before {
		content: '';
		background-color: ${theme.colors.marineBlack};
		opacity: 0.2;
		border-radius: 100%;
		width: 32px;
		height: 32px;
		position: absolute;
		display: block;
		top: 4.5px;
		left: 3.5px;
		transform: scale3d(0, 0, 0);
		transition: transform 400ms ease 0s;
	}

	svg {
		position: relative;
	}

	&:focus,
	&:hover {
		&::before {
			transform: scale3d(1, 1, 1);
		}
	}
`
