import styled from 'styled-components'

import { TextProps } from './types'

export const Wrapper = styled.p`
	display: flex;
	align-items: center;
	padding: 0 20px;
`
export const Text = styled.span<TextProps>`
	display: inline-block;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 0.44px;
	line-height: 24px;
	color: ${(props) => props.color};
	margin-left: 8px;
`
