import styled from 'styled-components'

import { theme } from '@damen/ui'

import { LOADER } from '@src/constants/zIndexes'

import { LoaderWrapperProps } from './types'

export const FormButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`

export const FormButtonRightCol = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-grow: 1;
	flex-shrink: 1;
`

export const FormLoaderTitleWrapper = styled.div`
	display: flex;
	justify-content: center;
`

export const FormLoaderTitle = styled.div`
	margin-right: 16px;
`

export const LoaderWrapper = styled.div<LoaderWrapperProps>`
	display: flex;
	align-items: center;
	justify-self: center;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: ${LOADER};
	transition: opacity ${theme.timing.default} ease-in-out;
	opacity: ${(props) => (props.show ? 1 : 0)};
	pointer-events: ${(props) => (props.show ? 'auto' : 'none')};

	&::before {
		content: '';
		display: block;
		background-color: white;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0.8;
	}

	> div {
		margin: auto;
		position: relative;
	}
`
