import { useEffect } from 'react'

interface Errors {
	[key: string]: string
}

interface Options {
	yOffset?: number
	target?: HTMLElement | Window
}

const useScrollToFieldError = (
	errors: Errors,
	fields: string[],
	options?: Options
) => {
	const yOffset = options?.yOffset || -30
	const target = options?.target || window

	useEffect(() => {
		let fieldInError

		// Loop through fields and check first field to have an error
		const { length } = fields
		for (let i = 0; i < length; i += 1) {
			if (errors[fields[i]]) {
				fieldInError = fields[i]
				break
			}
		}

		if (fieldInError) {
			const element: HTMLElement = document.querySelector(
				`[name="${fieldInError}"]`
			)

			if (element) {
				setTimeout(() => {
					// Solution taken from https://stackoverflow.com/questions/24665602/scrollintoview-scrolls-just-too-far
					const y =
						element.getBoundingClientRect().top +
						window.pageYOffset +
						yOffset
					target.scrollTo({
						top: y,
						behavior: 'smooth'
					})
				}, 100)
			}
		}
	}, [errors, fields, target, yOffset])
}

export default useScrollToFieldError
