import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { AnimatePresence } from 'framer-motion'

import { Button, Icon, theme } from '@damen/ui'

import { NEW_PARTREQUEST } from '@src/constants/Routes'

import { NewPartRequestNotificatitonStatus } from '@contexts/NewPartRequestCartContext/types'
import { useTranslationContext } from '@contexts/TranslationContext'

import { CloseButton, SnackbarIcon, Text, Wrapper } from './styles'
import { QuotationRequestNotificationProps } from './types'

const variants = {
	visible: {
		opacity: [0, 1],
		y: [20, 0],
		pointerEvents: 'all' as const
	},
	hidden: {
		opacity: 0,
		y: 20,
		pointerEvents: 'none' as const
	}
}

const QuotationRequestNotification: FC<QuotationRequestNotificationProps> = ({
	count,
	status
}) => {
	// Hooks
	const { translations } = useTranslationContext()
	const router = useRouter()

	// Local state
	const [isOpen, setIsOpen] = useState(false)
	const text = useMemo(() => {
		switch (status) {
			case NewPartRequestNotificatitonStatus.ADDED:
				return translations?.quotationAddedLabel ?? '[Added!]'
			case NewPartRequestNotificatitonStatus.REMOVED:
				return translations?.quotationRemovedLabel ?? '[Removed!]'
			case NewPartRequestNotificatitonStatus.UPDATED:
				return translations?.quotationUpdatedLabel ?? '[Updated!]'
			default:
				return ''
		}
	}, [status, translations])

	// Event handlers
	const goToRequestClickHandler = useCallback(() => {
		router.push(NEW_PARTREQUEST)
	}, [router])

	const closeNotificationClickHandler = useCallback(() => {
		setIsOpen(false)
	}, [setIsOpen])

	// On prop/state change
	useEffect(() => {
		setIsOpen(false)
		requestAnimationFrame(() => setIsOpen(true))

		const timer = setTimeout(() => {
			setIsOpen(false)
		}, 4000)

		return () => clearTimeout(timer)
	}, [count, setIsOpen])

	if (count === 0) {
		return null
	}

	const duration = theme.timing.numDefault / 1000

	return (
		<AnimatePresence>
			<Wrapper
				key={count}
				animate={isOpen ? 'visible' : 'hidden'}
				initial="hidden"
				status={status}
				transition={{ duration }}
				variants={variants}
			>
				<SnackbarIcon status={status}>
					{status === NewPartRequestNotificatitonStatus.ADDED && (
						<Icon.Check
							fill={theme.colors.greenLight}
							width={15}
							height={10}
						/>
					)}
					{status === NewPartRequestNotificatitonStatus.UPDATED && (
						<Icon.Exclamation
							fill={theme.colors.blueLight}
							width={18}
							height={18}
						/>
					)}
					{status === NewPartRequestNotificatitonStatus.REMOVED && (
						<Icon.Exclamation
							fill={theme.colors.blueLight}
							width={18}
							height={18}
						/>
					)}
				</SnackbarIcon>
				<Text>{text}</Text>
				{router.asPath !== NEW_PARTREQUEST && (
					<Button.Default
						type="button"
						buttonType="text"
						text={
							translations?.quotationGoToRequestLabel ??
							'[Go to requests]'
						}
						colorType="marineBlack"
						icon={Icon.LeftChevron}
						onClick={goToRequestClickHandler}
					/>
				)}
				<CloseButton onClick={closeNotificationClickHandler}>
					<Icon.CloseThin width={12} height={12} />
				</CloseButton>
			</Wrapper>
		</AnimatePresence>
	)
}

export default QuotationRequestNotification
