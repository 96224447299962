import React, { FC, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Icon } from '@damen/ui'
import { Overlay } from '@components/Menu/styles'

import { toggleScroll } from '@utils/scrollLock'
import {
	Main,
	ExtraPanelBody,
	ExtraPanelWrapper,
	Wrapper,
	Header,
	CloseButton,
	Title
} from './styles'
import { SidebarProps } from './types'

const Sidebar: FC<React.PropsWithChildren<SidebarProps>> = ({
	children,
	extraContentPanel = null,
	desktopWidth = 600,
	isOpen,
	onClose,
	title,
	isNarrow,
	...rest
}) => {
	const [root, setRoot] = useState<HTMLElement>()

	useEffect(() => {
		setRoot(document.getElementById('__next'))
	}, [])

	useEffect(() => {
		toggleScroll(isOpen)
		return () => toggleScroll(false)
	}, [isOpen])

	if (!root) return <></>

	const isNarrowComputed = isNarrow ?? extraContentPanel !== null

	return ReactDOM.createPortal(
		<>
			<Overlay isOpen={isOpen} onClick={onClose} />

			<Main isNarrow={isNarrowComputed} isOpen={isOpen}>
				{extraContentPanel && (
					<ExtraPanelWrapper
						isOpen={isOpen}
						isNarrow={isNarrowComputed}
					>
						<Header>
							{title && <Title>{title}</Title>}
							<CloseButton onClick={onClose}>
								<Icon.CloseThin width={18} height={18} />
							</CloseButton>
						</Header>
						<ExtraPanelBody>{extraContentPanel}</ExtraPanelBody>
					</ExtraPanelWrapper>
				)}
				<Wrapper
					isOpen={isOpen}
					isNarrow={isNarrowComputed}
					desktopWidth={desktopWidth}
					{...rest}
				>
					<Header>
						{title && <Title>{title}</Title>}
						<CloseButton onClick={onClose}>
							<Icon.CloseThin width={18} height={18} />
						</CloseButton>
					</Header>
					{children}
				</Wrapper>
			</Main>
		</>,
		root
	)
}

export default Sidebar
