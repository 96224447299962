import styled from 'styled-components'

export const Container = styled.div`
	display: block;
`

export const Item = styled.div`
	display: block;
	padding: 32px 0 27px;
	position: relative;

	&:not(:last-of-type)::after {
		content: '';
		width: 100%;
		position: absolute;
		bottom: 0;
		height: 1px;
		display: block;
		background-color: #99a2af;
		opacity: 0.15;
	}
`

export const TitleWrapper = styled.div`
	margin-bottom: 14px;
`
