import styled from 'styled-components'

export const Spacer = styled.span`
	display: block;
	min-height: 100vh;
`

export const StepWrapperItem = styled.div`
	display: flex;
	align-items: center;
	margin-right: 48px;
`

export const StepWrapper = styled.div`
	display: flex;
	margin-top: 32px;
	align-items: center;

	${StepWrapperItem}:first-child {
		span {
			margin-right: 16px;
		}
	}
`

export const TitleWrapper = styled.div`
	margin-bottom: 32px;
`

export const LabelWrapper = styled.div`
	margin-bottom: 12px;
	margin-top: 24px;
`
