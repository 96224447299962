import React, { FC, useCallback } from 'react'

import { Button, Icon } from '@damen/ui'

import Modal from '@components/UI/Modal'

import {
	Body,
	ButtonWrapper,
	CloseButton,
	Content,
	IconWrapper,
	Title,
	Wrapper
} from './styles'
import { AssetButtonTypes, AssetModalProps } from './types'

const AlertModal: FC<AssetModalProps> = ({
	body,
	labelCancel,
	labelConfirm,
	isOpen,
	onClose,
	title
}) => {
	const onCancelClick = useCallback(() => {
		if (onClose) {
			onClose(AssetButtonTypes.CANCEL)
		}
	}, [onClose])

	const onConfirmClick = useCallback(() => {
		if (onClose) {
			onClose(AssetButtonTypes.CONFIRM)
		}
	}, [onClose])

	return (
		<Modal.Default
			isOpen={isOpen}
			onClose={onClose as () => void}
			transparent
		>
			<Wrapper>
				<IconWrapper>
					<Icon.BowWaveSmall width={171} height={86} />
				</IconWrapper>
				<CloseButton onClick={onCancelClick} />
				<Content>
					<Title>{title}</Title>
					{body && <Body>{body}</Body>}
				</Content>
				<ButtonWrapper>
					{labelCancel && (
						<Button.Default
							type="button"
							buttonType="text"
							onClick={onCancelClick}
							text={labelCancel}
							icon={Icon.LeftChevron}
						/>
					)}
					<Button.Default
						type="button"
						onClick={onConfirmClick}
						text={labelConfirm}
						icon={Icon.LeftChevron}
					/>
				</ButtonWrapper>
			</Wrapper>
		</Modal.Default>
	)
}

export default AlertModal
