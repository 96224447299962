import React, { FC } from 'react'

import { Image } from './styles'
import { LogoProps } from './types'

const Logo: FC<LogoProps> = ({ imageSrc, onLoad }) => {
	return <Image src={imageSrc} alt="" onLoad={onLoad} />
}

export default Logo
