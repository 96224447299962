import React, { useCallback, useContext, useMemo, useState } from 'react'

import {
	NotificationContextProps,
	NotificationProviderProps,
	NotificationTypes
} from '@contexts/NotificationContext/types'

const NotificationContext = React.createContext<NotificationContextProps>({})

export const useNotificationContext = () => {
	return useContext(NotificationContext)
}

const DEFAULT_NOTIFICATION_OPTIONS = {
	showAfterDelay: 0,
	hideAfterDelay: 5000,
	autoHide: true
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
	children
}) => {
	const [notification, setNotification] = useState({
		count: 0,
		message: null,
		options: DEFAULT_NOTIFICATION_OPTIONS
	})

	const sendNotification = useCallback(
		(
			notificationType: NotificationTypes,
			message: string,
			options = DEFAULT_NOTIFICATION_OPTIONS
		) => {
			setNotification((state) => {
				return {
					...state,
					count: state.count + 1,
					notificationType,
					message,
					options: { ...state.options, ...options }
				}
			})
		},
		[]
	)

	const notificationProviderValue = useMemo(() => {
		return {
			notification,
			sendNotification
		}
	}, [notification, sendNotification])

	return (
		<NotificationContext.Provider value={notificationProviderValue}>
			{children}
		</NotificationContext.Provider>
	)
}

export default NotificationContext
