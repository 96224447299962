import { Validation } from './types'

export const ValidationRules = {
	minimumCharacters: 3,
	maxTextInput: 100,
	maxCaseMessage: 1000,
	maxTextArea: 1500,
	maxName: 265,
	maxFirstName: 40,
	maxLastName: 80,
	maxPhoneNumber: 20,
	// The technical limit for an email address is 64 characters before the @ sign and 254 after,
	// allowing for a total length of 319 characters (including the @ sign)
	// https://sendgrid.com/en-us/blog/email-marketing-length-best-practices#span-stylefontweight-400email-address-lengthspan
	// Current limit is SF with 80 characters
	maxEmail: 80,
	minVerificationCode: 6,
	maxVerificationCode: 6
}

export const ValidationRegexes: Record<string, RegExp> = {
	phone: /^[0-9]*$/,
	notOnlyWhitespace: /\S/
}

export const min = (messages: Validation, value: number) => {
	return messages.minimum.replace('[VALUE]', `${value}`)
}

export const max = (messages: Validation, value: number) => {
	return messages.maximum.replace('[VALUE]', `${value}`)
}

export const maxTextMessage = (messages: Validation) =>
	max(messages, ValidationRules.maxTextInput)
export const maxTextAreaMessage = (messages: Validation) =>
	max(messages, ValidationRules.maxTextArea)
export const maxCaseMessageMessage = (messages: Validation) =>
	max(messages, ValidationRules.maxCaseMessage)
export const maxNameMessage = (messages: Validation) =>
	max(messages, ValidationRules.maxName)

export const maxFirstNameMessage = (messages: Validation) =>
	max(messages, ValidationRules.maxFirstName)
export const maxLastNameMessage = (messages: Validation) =>
	max(messages, ValidationRules.maxFirstName)
export const maxPhoneMessage = (messages: Validation) =>
	max(messages, ValidationRules.maxPhoneNumber)
export const maxEmailMessage = (messages: Validation) =>
	max(messages, ValidationRules.maxEmail)

export const minMessage = (messages: Validation) =>
	min(messages, ValidationRules.minimumCharacters)

export const minVerificationCode = (messages: Validation) =>
	min(messages, ValidationRules.minVerificationCode)
