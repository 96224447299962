import styled from 'styled-components'

export const SelectAnchor = styled.object`
	display: block;
	width: 100%;
	height: 0;
`

export const SelectWrapper = styled.div`
	label > p {
		font-size: 14px;
		line-height: 1e;
	}
`
