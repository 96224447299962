import styled from 'styled-components'

import { theme } from '@damen/ui'

import { FileUploadHotspotProps } from './types'

export const FileUploadHotspot = styled.div<FileUploadHotspotProps>`
	display: flex;
	font-family: ${theme.fonts.body};
	flex-direction: column;
	width: 100%;
	min-height: 112px;
	padding: 32px 24px;
	border: ${({ hasError }) =>
		hasError
			? `3px solid ${theme.colors.blue}`
			: `1px solid ${theme.colors.greyDark}`};
	border-radius: 5px;
	margin-top: 8px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: border 250ms ease-in-out, background-color 250ms ease-in-out;
	background-color: ${({ isUploading }) =>
		isUploading ? theme.colors.blueIce : 'transparent'};
	position: relative;

	&:hover {
		background-color: ${theme.colors.greyLight};
	}

	&:focus {
		border: 1px dashed ${theme.colors.blue};
		outline: none;
	}
`

export const FileUploadInput = styled.input`
	display: none;
`

export const FileUploadIcon = styled.span`
	width: 18px;
	height: 24px;
	display: block;
	margin-bottom: 16px;
`

export const FileUploadPlaceholder = styled.p`
	color: ${theme.colors.grey};
	font-size: 14px;
	letter-spacing: 0.6px;
	line-height: 24px;
	margin: 0 auto;
`

export const FileUploadError = styled.p`
	color: ${theme.colors.blue};
	font-weight: 500;
	width: 100%;
	margin: 8px 0 0 19px;
	line-height: 1;
	font-size: 14px;
`
