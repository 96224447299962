export enum AssetButtonTypes {
	CANCEL = 'cancel',
	CONFIRM = 'confirm'
}

export interface AssetModalProps {
	body?: string
	labelCancel?: string
	labelConfirm: string
	isOpen: boolean
	onClose: (type: AssetButtonTypes) => void
	title: string
}

export interface CloseButtonProps {
	onClick: (e: Event) => void
}
