import { Asset } from '@graphql/graphql'

import { DropdownItemWithParentModel } from '@damen/ui'

const mapAssetsToMultiselectOptions = (
	assets: Asset[],
	initialValue?: string
): DropdownItemWithParentModel[] =>
	assets?.map((asset) => {
		return new DropdownItemWithParentModel(
			{
				checked: asset.id === initialValue,
				disabled: false,
				label: `${asset.name} - ${asset.yardNumber}`,
				name: asset.id,
				readOnly: false,
				value: asset.id
			},
			'assets'
		)
	})

export default mapAssetsToMultiselectOptions
