import React from 'react'
import styled from 'styled-components'
import { Icon, theme } from '@damen/ui'

const Wrapper = styled.div`
	position: relative;
	display: block;
	padding: ${theme.layout.spacingM} 0;
`

const Content = styled.div<{ variant: 'black' | 'white' }>`
	position: relative;
	display: block;
	color: ${({ variant }) =>
		variant === 'black' ? theme.colors.marineBlack : theme.colors.white};
	font-size: ${theme.typography.fontSizeText}px;
	line-height: 1.88;
	margin-top: ${theme.layout.spacingM};
`

interface Props {
	content: string
	variant?: 'black' | 'white'
	showBowWave?: boolean
}

const ErrorState: React.FC<Props> = ({
	content,
	variant = 'black',
	showBowWave = false
}) => (
	<Wrapper>
		{showBowWave && (
			<Icon.BowWave
				width={86}
				height={64}
				fill={variant === 'white' ? '#ffffff' : '#2546b1'}
			/>
		)}
		<Content variant={variant}>{content}</Content>
	</Wrapper>
)

export default ErrorState
