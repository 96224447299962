import { ApolloError, LazyQueryExecFunction } from '@apollo/client'
import { OptionType } from '@damen/ui/lib/cjs/components/Form/Select'
import { GetCompaniesQuery, GetCompaniesQueryVariables } from '@graphql/graphql'

export interface DxpFormArrayItem {
	type: string
	key: string
	readOnly: boolean
	name: string
	label: string
	placeholder: string
}

export interface DxpFormFieldItem {
	label: string
	name: string
	type: 'textarea' | 'string' | 'select'
	placeholder: string
	options?: OptionType[]
}

export interface DxpForm {
	title?: string
	initialValues: DxpFormInitialValues
	validationSchema: any
	setTitle?: (value: string) => void
	loading?: boolean
	error?: ApolloError
}

export interface DxpFormStepper extends DxpForm {
	formComplete: () => void
	resetForm: () => void
	currentStep?: number
	previousStep?: any
	goToNext?: () => void
	goToPrevious?: () => void
	goToStep?: (index: number) => void
	setState?: (state: FormState) => void
	state?: FormState
}

export interface DxpFormUserInvite extends DxpForm {
	loadCompanies: LazyQueryExecFunction<
		GetCompaniesQuery,
		GetCompaniesQueryVariables
	>
}

export interface DxpFormUserEdit extends DxpForm {
	loadCompanies: LazyQueryExecFunction<
		GetCompaniesQuery,
		GetCompaniesQueryVariables
	>
}

export interface DxpFormUserReactivate extends DxpForm {
	loadCompanies: LazyQueryExecFunction<
		GetCompaniesQuery,
		GetCompaniesQueryVariables
	>
}

export interface DxpFormInitialValues {
	[key: string]: any
}

export interface DxpFormSteps {
	title?: string
	description?: string
	key: string
	fieldsArray: DxpFormFieldItem[][]
}

export enum FormState {
	error = 'error',
	complete = 'complete',
	inProgress = 'in progress'
}
