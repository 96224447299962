import { FileUploadStoryblok } from '@graphql/storyblokcomponents'
import { FileState } from './Default/types'

export enum Mode {
	READ_ONLY = 'read-only',
	READ_AND_WRITE = 'read-and-write'
}

// NOTE: This is used for the FileUpload component
export interface FileUploadFileReference extends File {
	id: string
	url: string
}

// NOTE: Thi sis used for the UploadFile mutation
export interface FileReference {
	url: string
}

export interface FileUploadErrorProps {
	[key: string]: string
}

export interface FileUploadProps extends AttachmentsProps {
	initialValue?: FileUploadFileReference[]
	error?: string
	mode?: Mode
	noTopPadding?: boolean
	onFileSelect?: (files: FileUploadFileReference[]) => void
	onFileUploading?: () => void
	onFileUploadComplete?: (state: FileState) => void
}

export interface AttachmentsProps {
	acceptedFileTypes?: FileUploadStoryblok['acceptedFileTypes']
	description?: string
	maxFileSizeMb?: number
	maxNumberFiles?: number
	placeholder?: string
	errorFileTooBig?: string
	errorFileUpload?: string
	errorTooManyFiles?: string
	errorWrongFileType?: string
	uploading?: string
}
