// Taken from https://usehooks-typescript.com/react-hook/use-interval

import { useEffect, useRef } from 'react'

function useInterval(callback: () => void, delay: number | null) {
	const savedCallback = useRef(callback)

	// Remember the latest callback if it changes.
	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	useEffect(() => {
		// Don't schedule if no delay is specified.
		// Note: 0 is a valid value for delay.
		if (!delay && delay !== 0) {
			return () => {}
		}

		const id = setInterval(() => savedCallback.current(), delay)
		return () => {
			clearInterval(id)
		}
	}, [delay])
}

export default useInterval
