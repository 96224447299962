import React, { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import { SessionProvider } from 'next-auth/react'

import { theme } from '@damen/ui'

import { AuthenticatedApolloProvider } from '@lib/apollo/AuthenticatedApolloProvider'
import { pageview as gtagPageView } from '@lib/gtag'
import { gtmPageView } from '@lib/gtm'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'

import ApplicationInsightsTracker from '@components/ApplicationInsightsTracker'
import Authentication from '@components/Authentication'
import Layout from '@components/Layout/Layout'

import { createApplicationInsights } from '@utils/appInsights'
import { useOnRouteChangeComplete } from '@hooks/useOnRouteChange'
import LanguageRedirect from '@components/LanguageRedirect'
import { AppContextProvider } from '@contexts/RuntimeConfigProvider'

declare global {
	interface Window {
		storyblok: any
		dataLayer: any
	}
}

type MyAppProps = {
	Component: any
	pageProps: any
}

const MyApp: React.FC<MyAppProps> = ({ Component, pageProps }) => {
	const { session } = pageProps

	useOnRouteChangeComplete((url) => {
		gtagPageView(url)
		gtmPageView(url, document.title)
	})

	const { reactPlugin } = useMemo(() => createApplicationInsights(), [])

	// Prevent passing whole object back to value Provider
	const publicRuntimeConfig = useMemo(() => {
		return {
			publicRuntimeConfig: pageProps.publicRuntimeConfig
		}
	}, [pageProps.publicRuntimeConfig])

	return (
		<AppInsightsContext.Provider value={reactPlugin}>
			<ApplicationInsightsTracker>
				<SessionProvider
					session={session}
					refetchInterval={
						process.env.NEXT_PUBLIC_SESSION_REFETCH_TIMER
							? Number(
									process.env
										.NEXT_PUBLIC_SESSION_REFETCH_TIMER
							  )
							: undefined
					}
				>
					<AuthenticatedApolloProvider pageProps={pageProps}>
						<AppContextProvider value={publicRuntimeConfig}>
							<ThemeProvider theme={theme}>
								<Authentication>
									<LanguageRedirect>
										<Layout>
											<Component {...pageProps} />
										</Layout>
									</LanguageRedirect>
								</Authentication>
							</ThemeProvider>
						</AppContextProvider>
					</AuthenticatedApolloProvider>
				</SessionProvider>
			</ApplicationInsightsTracker>
		</AppInsightsContext.Provider>
	)
}

export default MyApp
