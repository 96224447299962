export enum FileUploadStates {
	INITIAL = 'initial',
	UPLOADING = 'uploading',
	WARNING = 'warning',
	COMPLETE = 'complete',
	ERROR = 'error'
}

export interface FileUploadHotspotProps {
	isUploading?: boolean
	hasError?: boolean
}

export enum FileState {
	ERROR = 'ERROR',
	SUCCES = 'SUCCES'
}
