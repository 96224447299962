import { useCallback } from 'react'

import { useNotificationContext } from '@contexts/NotificationContext'
import { useTranslationContext } from '@contexts/TranslationContext'
import { NotificationTypes } from '@contexts/NotificationContext/types'

import { SnackbarNotificationTypes } from '@components/UI/Snackbar/types'

const useError = () => {
	// Contexts
	const { sendNotification } = useNotificationContext()
	const { translations } = useTranslationContext()

	const showError = useCallback(
		(title: string = null, showAfterDelay = 0) => {
			sendNotification(
				NotificationTypes.SNACKBAR,
				{
					snackbarType: SnackbarNotificationTypes.ERROR,
					title: title ?? translations?.defaultErrorMessage
				},
				{
					showAfterDelay
				}
			)
		},
		[sendNotification, translations]
	)

	return { showError }
}

export default useError
