import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Permissions, useGetSupportQuery } from '@graphql/graphql'

import { useLocale } from '@hooks/useLocale'
import NewContactFormProvider from '@contexts/NewRequestForInformationContext'

import ErrorState from '@components/UI/Error'
import Sidebar from '@components/Sidebar'
import { usePermissions } from '@hooks/usePermission'
import ContactForm from './components/ContactForm'
import ContactInfo from './components/ContactInfo'

import { mapContent } from './helpers'
import { ContactModalProps, ContactModalResponseProps } from './types'
import {
	ContactFormWrapper,
	ContactInfoDesktopWrapper,
	ContactInfoMobileWrapper,
	ErrorStateWrapper,
	ContactInfoWrapper
} from './styles'

const ContactModal = ({ isOpen, onClose }: ContactModalProps) => {
	const { locale } = useLocale()

	const { data, loading, error } = useGetSupportQuery({
		variables: {
			language: locale
		},
		skip: !isOpen
	})

	const serviceRequestsWritePermission = usePermissions(
		Permissions.ServiceRequestsWrite
	)
	const hasWritePermission =
		!serviceRequestsWritePermission.isLoading &&
		serviceRequestsWritePermission.hasAcquired

	const elementRef = useRef()

	const { formContent, contactContent, validation } =
		useMemo((): ContactModalResponseProps => {
			if (data) {
				return mapContent(
					data.GlobalItem?.content?.global[0],
					data.attachments
				)
			}

			return {
				contactContent: null,
				formContent: null,
				validation: null
			}
		}, [data])

	const [intialized, setInitialized] = useState(false)
	const [contactModalKey, setContactModalKey] = useState(0)

	const initialiseHandler = useCallback(async () => {
		if (
			isOpen &&
			!intialized &&
			!loading &&
			contactContent &&
			formContent &&
			validation
		) {
			setInitialized(true)
		}
	}, [
		contactContent,
		formContent,
		isOpen,
		intialized,
		loading,
		setInitialized,
		validation
	])

	useEffect(() => {
		initialiseHandler()

		if (isOpen) {
			;(elementRef?.current as HTMLDivElement)?.scrollTo(0, 0)
		}
	}, [initialiseHandler, isOpen])

	// Same 'reset form' solution as used for the PartsModal
	useEffect(() => {
		setContactModalKey((previous) => previous + 1)
	}, [isOpen, setContactModalKey])

	if (!intialized && loading) {
		return null
	}

	if (!hasWritePermission) {
		return (
			<Sidebar isOpen={isOpen} onClose={onClose} isNarrow>
				<NewContactFormProvider>
					<ContactFormWrapper>
						<ContactInfoMobileWrapper>
							<ContactInfo {...contactContent} />
						</ContactInfoMobileWrapper>
						<ContactInfoDesktopWrapper>
							<ContactInfoWrapper>
								<ContactInfo {...contactContent} />
							</ContactInfoWrapper>
						</ContactInfoDesktopWrapper>
					</ContactFormWrapper>
				</NewContactFormProvider>
			</Sidebar>
		)
	}

	return (
		<Sidebar
			isOpen={isOpen}
			onClose={onClose}
			extraContentPanel={
				isOpen &&
				!error && (
					<ContactInfoDesktopWrapper>
						<ContactInfo {...contactContent} />
					</ContactInfoDesktopWrapper>
				)
			}
		>
			{error && (
				<ErrorStateWrapper>
					{/* The query is only fetching content from the CMS, if this fails we also cannot get a translation for the error */}
					<ErrorState content="Something went wrong" showBowWave />
				</ErrorStateWrapper>
			)}
			{!error && (
				<NewContactFormProvider>
					<ContactFormWrapper>
						<ContactInfoMobileWrapper>
							<ContactInfo {...contactContent} />
						</ContactInfoMobileWrapper>
						<ContactForm
							key={contactModalKey}
							content={formContent as any}
							validation={validation}
							onFormSubmit={onClose}
						/>
					</ContactFormWrapper>
				</NewContactFormProvider>
			)}
		</Sidebar>
	)
}

export default ContactModal
