import React, { useEffect } from 'react'
import { Icon } from '@damen/ui'
import { useSession } from 'next-auth/react'

import { useHeaderContext } from '@contexts/HeaderContext'
import ErrorState from '@components/UI/Error'
import ServiceNavigation from '@components/ServiceNavigation'
import Navigation from '@components/Navigation'
import { toggleScroll } from '@utils/scrollLock'

import { Wrapper, Overlay, CloseButton, Header, Body, Footer } from './styles'
import { MenuProps } from './types'

const Menu: React.FC<MenuProps> = ({ data, loading, error }) => {
	const { menuOpened, menuToggle } = useHeaderContext()
	const { data: session } = useSession()

	useEffect(() => {
		toggleScroll(menuOpened)
	}, [menuOpened])

	return (
		<>
			<Overlay isOpen={menuOpened} onClick={menuToggle} />

			<Wrapper isOpen={menuOpened}>
				<Header>
					<CloseButton isOpen={menuOpened} onClick={menuToggle}>
						<Icon.CloseThin width={16} height={16} />
					</CloseButton>
				</Header>

				{error && (
					<Body>
						<ErrorState
							content={
								data?.menuItems?.errorDescription ??
								'Something went wrong while retrieving the menu'
							}
							variant="white"
							showBowWave
						/>
					</Body>
				)}

				{!error && (
					<>
						<Body>
							<Navigation data={data} loading={loading} />
						</Body>

						<Footer>
							<ServiceNavigation
								data={data}
								loading={loading}
								session={session}
							/>
						</Footer>
					</>
				)}
			</Wrapper>
		</>
	)
}

export default Menu
