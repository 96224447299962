import { useEffect } from 'react'

const disableScroll = () => {
	document.querySelector('body').classList.add('disable-scroll')
}

const enableScroll = () => {
	document.querySelector('body').classList.remove('disable-scroll')
}

export const toggleScroll = (disable) => {
	if (disable) {
		disableScroll()
	} else {
		enableScroll()
	}
}

export const useDisablescrollLock = () => {
	useEffect(() => {
		disableScroll()

		return () => {
			enableScroll()
		}
	}, [])
}
