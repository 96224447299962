import styled from 'styled-components'

import { theme } from '@damen/ui'

interface FormHeaderElementProps {
	doubleSpacing: boolean
}

export const FormHeaderElement = styled.div<FormHeaderElementProps>`
	margin-bottom: ${(props) => (props.doubleSpacing ? `56px` : `32px`)};
`

export const FormTitle = styled.h3`
	font-size: 20px;
	line-height: 32px;
	margin-bottom: 32px;
	color: ${theme.colors.marineBlack};
`
