import styled from 'styled-components'

import { theme } from '@damen/ui'

import { MODAL } from '@src/constants/zIndexes'
import { hexToRgb } from '@utils/helper'

export const Title = styled.h3`
	font-size: ${theme.typography.fontSizeHeadingThreeMobile}px;
	line-height: ${theme.typography.lineHeightHeadingThreeMobile};
	color: ${theme.colors.white};
	margin-bottom: 10px;
	padding: 16px;

	@media ${theme.legacyMediaQueries.sm} {
		font-size: ${theme.typography.fontSizeHeadingThree}px;
		line-height: ${theme.typography.lineHeightHeadingThree};
		margin-bottom: 40px;
		padding: 0;
	}
`

export const Container = styled.div<{ transparent?: boolean }>`
	display: flex;
	width: 100%;
	height: 100%;
	background-color: ${({ transparent }) =>
		transparent === true ? 'transparent' : `${theme.colors.white}`};

	@media ${theme.legacyMediaQueries.sm} {
		background: transparent;
		align-items: center;
		padding: 24px;
	}
`

export const Wrapper = styled.div<{
	maxWidth: number
	minHeight: number
	transparent?: boolean
}>`
	pointer-events: all;
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;
	pointer-events: none;

	@media ${theme.legacyMediaQueries.sm} {
		justify-content: center;
	}

	@media ${theme.legacyMediaQueries.sm} {
		display: block;
		margin: auto;
		max-width: ${({ maxWidth }) => maxWidth || 1208}px;

		${({ minHeight }) => minHeight && `${minHeight}px`}
	}
`

export const CloseButton = styled.button`
	cursor: pointer;
	background: transparent;
	border: none;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	position: relative;
	transition: ${theme.timing.fast};
	display: flex;
	justify-content: center;
	align-items: center;

	:hover,
	:focus {
		outline: none;
		background: ${theme.colors.blueIce};
	}

	@media ${theme.legacyMediaQueries.md} {
		order: 1;
	}
`

export const Header = styled.header`
	display: flex;
	position: sticky;
	z-index: ${MODAL + 1};
	padding: 8px;
	justify-content: flex-end;
	align-items: center;
	top: 0;

	@media ${theme.legacyMediaQueries.sm} {
		display: none;
	}
`

export const Content = styled.div<{ transparent?: boolean }>`
	position: relative;
	padding: 16px;
	height: calc(100dvh - 130px);
	overflow-y: hidden;
	display: flex;
	width: 100%;

	> div {
		width: 100%;
		pointer-events: auto;
		cursor: default;

		> div {
			height: 100%;
			padding: 0;
		}
	}

	@media ${theme.legacyMediaQueries.sm} {
		> div {
			box-shadow: 0 0 8px 0
				${hexToRgb(theme.colors.marineBlack, {
					alpha: 0.23,
					format: 'css'
				})};

			> div {
				height: 100%;
				padding: 0;
			}
		}

		padding: ${({ transparent }) =>
			transparent === true ? '0' : '0 32px 32px'};
	}
`
