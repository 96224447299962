import { theme } from '@damen/ui'
import styled from 'styled-components'

export const ContactFormWrapper = styled.div`
	position: relative;
	overflow-y: auto;
`

export const ContactInfoMobileWrapper = styled.div`
	position: relative;
	z-index: 2;
	padding: 24px 20px 0;
	background-color: ${theme.colors.blueIce};

	@media ${theme.legacyMediaQueries.md} {
		display: none;
	}
`

export const ContactInfoDesktopWrapper = styled.div`
	display: none;

	@media ${theme.legacyMediaQueries.md} {
		display: block;
	}
`

export const ErrorStateWrapper = styled.div`
	padding: 24px 20px 0;

	@media ${theme.legacyMediaQueries.md} {
		padding: 40px 36px 0;
	}
`

export const ContactInfoWrapper = styled.div`
	padding: 20px 40px 40px;
`
