import { motion } from 'framer-motion'
import styled from 'styled-components'

import { TextProps } from './types'

export const Wrapper = styled(motion.button)`
	display: flex;
	align-items: center;
	border: 0;
	height: 48px;
	background-color: transparent;
	margin: 0;
	padding: 0;
	cursor: pointer;
	position: relative;
	overflow: hidden;

	sup {
		font-size: 8px;
		line-height: 8px;
		position: relative;
		top: -1px;
	}

	&:focus,
	&:hover {
		> span {
			&::before {
				transform: scale3d(1, 1, 1);
				transform-origin: 0% 50%;
			}
		}
	}
`

export const Inner = styled.span`
	padding: 0 20px;
	white-space: pre;
`

export const Text = styled.span<TextProps>`
	display: inline-block;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 0.44px;
	line-height: 24px;
	color: ${(props) => props.color};
	margin-left: 8px;
	position: relative;
	white-space: pre;

	&::before {
		content: '';
		width: 100%;
		height: 2px;
		background-color: ${(props) => props.color};
		position: absolute;
		bottom: 2px;
		left: 0;
		transform: scale3d(0, 1, 1);
		transform-origin: 100% 50%;
		transition: transform 400ms ease 0s;
	}
`
