import React, { useCallback } from 'react'
import Link from 'next/link'
import SkeletonLoader from '@components/UI/SkeletonLoader'

import { useHeaderContext } from '@contexts/HeaderContext'

import { SUPPORT } from '@src/constants/Keys'

import { getMappedLinkHref } from '@utils/linkResolver'
import { MenuItem } from './styles'
import { NavigationProps } from './types'

const Navigation = ({ data, loading }: NavigationProps) => {
	const { menuToggle, setSupportOpened } = useHeaderContext()

	const menuItemSupportClickHandler = useCallback(() => {
		setSupportOpened(true)
		menuToggle(null)
	}, [menuToggle, setSupportOpened])

	if (loading) {
		const menuArray = [1, 2, 3, 4, 5]
		return (
			<ul>
				{menuArray.map((item) => (
					<li key={item}>
						<SkeletonLoader />
					</li>
				))}
			</ul>
		)
	}

	if (data) {
		const mainMenuItems = data?.menuItems?.mainMenu

		return (
			<nav>
				<ul>
					{mainMenuItems &&
						mainMenuItems.map((item) =>
							item?.type?.toLowerCase() === SUPPORT ? (
								<MenuItem
									visibility={item.visibility}
									key={`page__${item.title}`}
								>
									<button
										type="button"
										onClick={menuItemSupportClickHandler}
									>
										{item.title}
									</button>
								</MenuItem>
							) : (
								<MenuItem
									visibility={item.visibility}
									key={`page__${item.title}`}
								>
									<Link
										href={getMappedLinkHref(item.link.url)}
										onClick={menuToggle}
									>
										{item.title}
									</Link>
								</MenuItem>
							)
						)}
				</ul>
			</nav>
		)
	}

	return <></>
}

export default Navigation
