import React, { FC, useCallback, useMemo, useRef, useState } from 'react'

import { Button, Icon, theme } from '@damen/ui'

import useClickOutside from '@hooks/useClickOutside'

import { ContactInfoItem } from '../ContactInfo/types'

import { Item, ItemButton, ItemButtonLabel, List, Wrapper } from './styles'
import { ContactButtonItemProps, ContactButtonTypes } from './types'

const ContactButton: FC<ContactInfoItem> = ({
	email,
	mobile,
	labelContact,
	phone
}) => {
	// Local state
	const [isOpen, setIsOpen] = useState(false)
	const wrapperRef = useRef(null)

	const items: ContactButtonItemProps[] = useMemo(() => {
		const response = []

		if (email) {
			response.push({
				id: ContactButtonTypes.EMAIL,
				label: email,
				value: email,
				Icon: Icon.EnvelopeRegular,
				href: `mailto:${email}`
			})
		}

		if (mobile) {
			response.push({
				id: ContactButtonTypes.MOBILE,
				label: mobile,
				value: mobile,
				Icon: Icon.PhoneAlt,
				href: `tel:${mobile}`
			})
		}

		if (phone) {
			response.push({
				id: ContactButtonTypes.PHONE,
				label: phone,
				value: phone,
				Icon: Icon.PhoneAlt,
				href: `tel:${phone}`
			})
		}
		return response
	}, [email, mobile, phone])
	const { length } = items

	const onClose = () => {
		setIsOpen(false)
	}

	// Hooks
	useClickOutside(wrapperRef, () => onClose())

	// Event handlers
	const clickHandler = useCallback(() => {
		setIsOpen(!isOpen)
	}, [isOpen])

	return (
		<Wrapper ref={wrapperRef}>
			<Button.Default
				buttonType="text"
				text={labelContact}
				colorType="blue"
				icon={Icon.LeftChevron}
				onClick={clickHandler}
			/>
			{length > 0 && (
				<List
					initial={{ opacity: 0, y: -30, pointerEvents: 'none' }}
					animate={{
						opacity: isOpen ? 1 : 0,
						y: isOpen ? 0 : -30,
						pointerEvents: isOpen ? 'auto' : 'none'
					}}
					transition={{
						duration: 0.2
					}}
				>
					{items.map((item) => (
						<Item key={item.id}>
							<ItemButton href={item.href}>
								<item.Icon
									width={16}
									height={16}
									fill={theme.colors.blue}
								/>
								<ItemButtonLabel>{item.label}</ItemButtonLabel>
							</ItemButton>
						</Item>
					))}
				</List>
			)}
		</Wrapper>
	)
}

export default ContactButton
