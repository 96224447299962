import React, { FC } from 'react'
import { Field } from 'formik'

import Input from '@damen/ui/lib/cjs/components/Form/Input'

import { FieldInputProps } from './types'

const FieldInput: FC<FieldInputProps> = ({
	hideLabel = false,
	label,
	name,
	onChange,
	placeholder,
	readOnly = false,
	setFieldValue
}) => (
	<Field name={name}>
		{({ field, form: { touched, errors } }) => (
			<Input
				error={
					errors[name] !== '' && errors[name] !== undefined
						? `${label} ${errors[name]}`
						: ''
				}
				readOnly={readOnly}
				label={hideLabel ? '' : label}
				name={name}
				value={field.value}
				touched={touched[name] || ''}
				placeholder={placeholder}
				onChange={(event) => {
					// Callback for making update to header
					if (onChange) {
						onChange(event.target.value)
					}
					setFieldValue(name, event.target.value)
				}}
			/>
		)}
	</Field>
)

export default FieldInput
