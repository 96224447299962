import styled from 'styled-components'

import { theme } from '@damen/ui'

import Button from '@components/UI/Buttons'

import { CloseButtonProps } from './types'

export const Wrapper = styled.div`
	max-width: 582px;
	min-height: 412px;
	width: 100%;
	background-color: ${theme.colors.white};
	display: flex;
	flex-direction: column;
	margin: auto;
	padding: 32px;
	position: relative;

	@media ${theme.legacyMediaQueries.md} {
		padding: 40px 60px;
	}
`

export const CloseButton = styled(Button.CloseButton)<CloseButtonProps>`
	position: absolute;
	top: 24px;
	right: 24px;

	@media ${theme.legacyMediaQueries.smMax} {
		display: none;
	}
`

export const IconWrapper = styled.div`
	position: absolute;
	left: 58px;
	top: -20px;
`

export const Content = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`

export const Title = styled.h2`
	font-size: 40px;
	line-height: 52px;
	color: ${theme.colors.marineBlack};
	margin: auto;
`

export const Body = styled.p`
	margin: 0 auto auto;
	font-size: 16px;
	line-height: 30px;
	color: ${theme.colors.marineBlack};
	margin: 24px auto auto;
`

export const ButtonWrapper = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	width: 100%;
	border-top: 1px solid ${theme.colors.greyAccentLight};
	padding-top: 16px;

	> :first-child {
		margin: auto 8px auto auto;
	}

	> :last-child {
		margin: auto auto auto 8px;
	}

	@media ${theme.legacyMediaQueries.md} {
		padding-top: 24px;

		> :first-child {
			margin: auto 32px auto auto;
		}

		> :last-child {
			margin: auto auto auto 32px;
		}
	}
`
