import styled from 'styled-components'
import { motion } from 'framer-motion'

import { theme } from '@damen/ui'

import { hexToRgb } from '@utils/helper'

import { ItemImageProps } from './types'

export const Wrapper = styled(motion.div)`
	position: absolute;
	top: 16px;
	right: 16px;

	@media ${theme.legacyMediaQueries.mdMax} {
		display: none;
	}
`

export const List = styled.ul`
    display flex;
    align-items: center;
    height: 48px;
`

export const Item = styled.li`
	padding: 0;
	margin: auto;
	line-height: 0;
	position: relative;

	> span {
		display: block;
	}
`

export const ItemImage = styled(motion.li).withConfig({
	shouldForwardProp: (prop) => !['headerColor'].includes(prop)
})<ItemImageProps>`
	padding: 0;
	margin: auto;
	display: flex;
	line-height: 0;
	position: relative;
	padding: 0;
	position: relative;
	height: 48px;

	&:before {
		content: '';
		display: block;
		height: 48px;
		width: 1px;
		background-color: ${({ headerColor }) =>
			headerColor === 'blue'
				? hexToRgb(theme.colors.white, {
						alpha: 0.05,
						format: 'css'
				  })
				: theme.colors.greyAccentLight};
		position: absolute;
		left: 0;
		top: 0;
	}

	> span {
		display: block;
	}
`

export const IconCount = styled(motion.span)`
	font-weight: 500;
	color: ${theme.colors.white};
	border: solid 1px ${theme.colors.white};
	background-color: ${theme.colors.orange};
	position: absolute;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	width: 20px;
	height: 20px;
	font-size: 12px;
	top: 10px;
	right: 10px;

	@media ${theme.legacyMediaQueries.md} {
		width: 16px;
		height: 16px;
		font-size: 10px;
		top: 10px;
		left: 30px;
	}
`
