import { theme } from '@damen/ui'
import styled from 'styled-components'

import { ProfilePictureElementProps, ProfilePictureSize } from './types'

const ProfileSize = {
	[ProfilePictureSize.DEFAULT]: 104,
	[ProfilePictureSize.LARGE]: 156
}

const ProfileHeadWidth = {
	[ProfilePictureSize.DEFAULT]: 38,
	[ProfilePictureSize.LARGE]: 57
}

const ProfileHeadHeight = {
	[ProfilePictureSize.DEFAULT]: 39,
	[ProfilePictureSize.LARGE]: 58
}

const ProfileHeadTop = {
	[ProfilePictureSize.DEFAULT]: 22,
	[ProfilePictureSize.LARGE]: 33
}

const ProfileHeadLeft = {
	[ProfilePictureSize.DEFAULT]: 33,
	[ProfilePictureSize.LARGE]: 49
}

const ProfileBodyWidth = {
	[ProfilePictureSize.DEFAULT]: 68,
	[ProfilePictureSize.LARGE]: 102
}

const ProfileBodyHeight = {
	[ProfilePictureSize.DEFAULT]: 68,
	[ProfilePictureSize.LARGE]: 102
}

const ProfileBodyBottom = {
	[ProfilePictureSize.DEFAULT]: -30,
	[ProfilePictureSize.LARGE]: -45
}

const ProfileBodyLeft = {
	[ProfilePictureSize.DEFAULT]: 18,
	[ProfilePictureSize.LARGE]: 27
}

export const ProfilePictureWrapper = styled.div<ProfilePictureElementProps>`
	display: block;
	width: ${(props) => ProfileSize[props.size]}px;
	height: ${(props) => ProfileSize[props.size]}px;
	background-color: ${theme.colors.blueIce};
	border-radius: 100%;
	overflow: hidden;
	position: relative;
	flex-grow: 0;
	flex-shrink: 0;
`

export const ProfilePictureImage = styled.img`
	position: absoulte;
	width: 100%;
	height: 100%;
	object-fit: cover;
`

export const ProfilePictureNoImage = styled.div<ProfilePictureElementProps>`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	&::before {
		content: '';
		width: ${(props) => ProfileHeadWidth[props.size]}px;
		height: ${(props) => ProfileHeadHeight[props.size]}px;
		background-color: ${theme.colors.grey};
		display: block;
		position: absolute;
		top: ${(props) => ProfileHeadTop[props.size]}px;
		left: ${(props) => ProfileHeadLeft[props.size]}px;
		border-radius: 100%;
	}

	&::after {
		content: '';
		width: ${(props) => ProfileBodyWidth[props.size]}px;
		height: ${(props) => ProfileBodyHeight[props.size]}px;
		background-color: ${theme.colors.grey};
		display: block;
		position: absolute;
		bottom: ${(props) => ProfileBodyBottom[props.size]}px;
		left: ${(props) => ProfileBodyLeft[props.size]}px;
		border-radius: 100%;
	}
`
