import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import { theme } from '@damen/ui'

export const Title = styled.h3`
	display: flex;
	font-size: 20px;
	line-height: 32px;
	margin-bottom: 0;
`

export const PhoneNumber = styled.p`
	margin: 20px 0 27px 4px;
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 16px;
	letter-spacing: 0.5px;
	display: flex;

	svg {
		margin: auto 8px auto 0;
	}

	span {
		margin: auto auto auto 0;
	}

	@media ${theme.legacyMediaQueries.md} {
		margin: 37px 0 32px 4px;
	}
`

export const ClickablePhoneNumber = styled.a`
	text-decoration: none;
	color: ${theme.colors.blue};
`

export const ExpandingTitle = styled.h3<{ isOpen: boolean }>`
	display: flex;
	padding: 24px 0;
	font-size: 20px;
	line-height: 32px;
	margin-bottom: 0;

	span {
		flex-grow: 1;
		flex-shrink: 1;
	}

	svg {
		flex-grow: 0;
		flex-shrink: 0;
		margin: auto 0 auto auto;
	}

	@media ${theme.legacyMediaQueries.mdMax} {
		border-top: 1px solid ${theme.colors.greyAccentLight};
	}

	@media ${theme.legacyMediaQueries.md} {
		pointer-events: none;

		svg {
			display: none !important;
		}
	}

	${({ isOpen }) =>
		isOpen &&
		css`
			svg {
				transform: rotate(180deg);
			}
		`}
`

export const ContactInfoContainer = styled(motion.div)`
	@media ${theme.legacyMediaQueries.mdMax} {
		overflow: hidden;
		height: 0;
	}
`
