import React, { useEffect } from 'react'
import { signIn, useSession } from 'next-auth/react'

import Loading from '@components/Loading'
import styled from 'styled-components'
import { useRouter } from 'next/router'

const WholeScreen = styled.div`
	position: fixed;
	inset: 0;
`

const Authentication = ({ children }) => {
	const { data, status, update } = useSession()
	const { expires } = data ?? {}
	const permissions = data?.permissions ?? []
	const router = useRouter()

	// Redirect to signin page when unauthenticated
	useEffect(() => {
		if (status === 'loading') {
			return
		}

		if (status === 'unauthenticated') {
			signIn('azure')
		}
	}, [status])

	// Redirect to 401 page when user has no permissions
	useEffect(() => {
		if (
			status !== 'loading' &&
			status !== 'unauthenticated' &&
			permissions?.length < 1
		) {
			if (!['/401', '/404', '/500'].includes(router.pathname)) {
				router.replace({
					pathname: '/401',
					query: {
						type: 'unauthorized'
					}
				})
			}
		}
	}, [status, router, permissions?.length])

	// Refresh session before it actually expires
	useEffect(() => {
		if (!expires) {
			return () => {}
		}
		const expiresDate = new Date(expires)
		const updateSession = () => {
			if (document.visibilityState === 'visible') {
				// log
				console.log(
					'Update session - document visible and session has almost expired'
				)
				update()
			}
		}

		const interval = setTimeout(
			updateSession,
			// TO DEBUG: Change this to 1000 (each second)
			expiresDate.getTime() - Date.now() - 5 * 60 * 1000
		)
		return () => {
			if (interval) {
				clearTimeout(interval)
			}
		}
	}, [expires, update])

	// TODO: Enable in seperate PR
	// Refresh session when the user navigates back to the website tab
	// useEffect(() => {
	// 	const updateSession = () => {
	// 		if (document.visibilityState === 'visible') {
	// 			// log
	// 			console.log('Update session - document visible')
	// 			update()
	// 		}
	// 	}

	// 	window.addEventListener('visibilitychange', updateSession, false)
	// 	return () => {
	// 		window.removeEventListener('visibilitychange', updateSession, false)
	// 	}
	// }, [update])

	// If we have an active session (which might be refreshed) we do not want to destroy the whole React tree, but return the existing tree
	if (data?.user) {
		return children
	}

	if (status === 'loading') {
		return (
			<WholeScreen>
				<Loading.Default />
			</WholeScreen>
		)
	}

	// User is not authenticated, show this until the hooks have triggered to show login form
	if (status === 'unauthenticated') {
		return (
			<WholeScreen>
				<Loading.Default />
			</WholeScreen>
		)
	}

	// Fallback is showing the loader (until the users get authenticated?)
	return children
}

export default Authentication
