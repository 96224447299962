import React from 'react'

import FieldFileUpload from '@components/UI/Forms/FieldFileUpload'
import FieldInput from '@components/UI/Forms/FieldInput'
import FieldTextarea from '@components/UI/Forms/FieldTextarea'
import FormHeader from '@components/UI/Forms/FormHeader'
import {
	FormFieldHorizontal,
	FormRow,
	FormText
} from '@components/UI/Forms/FormUI'

import useScrollToFieldError from '@hooks/useScrollToFieldError'

import FieldSelect from '@components/UI/Forms/FieldSelect'
import { GeneralNewRequestForInformationProps } from './types'

const REQUIRED_FORM_FIELDS = [
	'generalCustomerId',
	'generalReference',
	'generalSubject',
	'generalQuestion',
	'generalPriority'
]

const GeneralFormFields = ({
	content,
	errors,
	setFieldValue,
	companies
}: GeneralNewRequestForInformationProps) => {
	useScrollToFieldError(errors, REQUIRED_FORM_FIELDS)

	if (!content) return null

	const {
		generalTitle,
		generalDescription,
		labelGeneralCompany,
		labelGeneralAttachments,
		labelGeneralQuestion,
		labelGeneralSubject,
		labelGeneralYourReference,
		placeholderGeneralCompany,
		placeholderGeneralQuestion,
		placeholderGeneralSubject,
		placeholderGeneralYourReference,
		attachments
	} = content

	return (
		<>
			<FormHeader title={generalTitle ?? '[Your question'}>
				<FormText>
					{generalDescription ??
						'[All fields in this section are mandatory]'}
				</FormText>
			</FormHeader>
			<FormFieldHorizontal>
				<FormRow>
					<FieldSelect
						label={labelGeneralCompany ?? '[Company]'}
						name="companyId"
						options={companies}
						placeholder={placeholderGeneralCompany}
						readOnly={false}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow>
					<FieldInput
						name="generalReference"
						label={labelGeneralYourReference ?? '[Your reference]'}
						placeholder={
							placeholderGeneralYourReference ??
							'[Give a title to this request]'
						}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow>
					<FieldInput
						name="generalSubject"
						label={labelGeneralSubject ?? '[Subject]'}
						placeholder={
							placeholderGeneralSubject ??
							'[A brief description about the problem]'
						}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow>
					<FieldTextarea
						name="generalQuestion"
						label={labelGeneralQuestion ?? '[Question]'}
						placeholder={
							placeholderGeneralQuestion ??
							'[Describe your questions or issue]'
						}
						setFieldValue={setFieldValue}
					/>
				</FormRow>
			</FormFieldHorizontal>
			<FormFieldHorizontal>
				<FormRow>
					<FieldFileUpload
						name="generalAttachments"
						label={labelGeneralAttachments ?? '[Attachments]'}
						setFieldValue={setFieldValue}
						{...attachments}
					/>
				</FormRow>
			</FormFieldHorizontal>
		</>
	)
}

export default GeneralFormFields
