export enum ProfilePictureSize {
	DEFAULT = 'default',
	LARGE = 'large'
}

export interface ProfilePictureProps {
	url?: string
	size?: ProfilePictureSize
}

export interface ProfilePictureElementProps {
	size: ProfilePictureSize
}
