import React from 'react'
import useLanguageRedirect from '@hooks/useLanguageRedirect'

const LanguageRedirect = ({ children }) => {
	useLanguageRedirect()

	return <>{children}</>
}

export default LanguageRedirect
