import React from 'react'
import { Icon, theme } from '@damen/ui'
import { LoaderWrapper, LoaderInline } from './styles'
import { LoaderProps } from './types'

const Loader = ({ fill, width, height, inline }: LoaderProps) => {
	const Wrapper = inline ? LoaderInline : LoaderWrapper
	return (
		<Wrapper>
			<Icon.Spinner
				width={width ?? 24}
				height={height ?? 24}
				fill={fill ?? theme.colors.blue}
			/>
		</Wrapper>
	)
}

export default Loader
