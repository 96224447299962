import styled from 'styled-components'

import { TextProps } from './types'

export const Text = styled.p<TextProps>`
	display: inline-block;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 0.44px;
	line-height: 24px;
	color: ${(props) => props.color};
	padding: 0 20px;
`
