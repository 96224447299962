import React, { FC, useCallback } from 'react'

import { Icon } from '@damen/ui'

import { useHeaderContext } from '@contexts/HeaderContext'

import { Inner, Text, Wrapper } from './styles'
import { SupportButtonProps } from './types'

const SupportButton: FC<SupportButtonProps> = ({ color, label }) => {
	const { setSupportOpened } = useHeaderContext()

	const buttonClickHandler = useCallback(() => {
		setSupportOpened(true)
	}, [setSupportOpened])

	return (
		<Wrapper onClick={buttonClickHandler}>
			<Inner>
				<Icon.LifeRing width={20} height={20} fill={color} />
				<Text color={color}>{label ?? '[Support]'}</Text>
			</Inner>
		</Wrapper>
	)
}

export default SupportButton
