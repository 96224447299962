import { Asset } from '@graphql/graphql'

import { DropdownItemWithParentModel } from '@damen/ui'

import { NON_DAMEN_VESSEL } from '@src/constants/Keys'
import mapAssetsToMultiselectOptions from './mapAssetsToMultiselectOptions'

const mapAssetsToMultiSelectWithNonDamenVessel = (
	assets: Asset[],
	label: string,
	value?: string
): DropdownItemWithParentModel[] => [
	...mapAssetsToMultiselectOptions(assets, value),

	// TODO: This should come from the backend.
	new DropdownItemWithParentModel(
		{
			checked: false,
			disabled: false,
			label,
			name: label,
			readOnly: false,
			value: NON_DAMEN_VESSEL
		},
		'assets'
	)
]

export default mapAssetsToMultiSelectWithNonDamenVessel
