import { SparePart } from '@graphql/graphql'
import { useRouter } from 'next/router'
import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState
} from 'react'

import {
	NewPartRequestStoreProps,
	NewPartRequestRowEntry,
	NewPartRequestNotificatitonStatus,
	NewPartsTranslations
} from './types'

export const calculateQuantity = (items: NewPartRequestRowEntry[]): number => {
	return items.reduce((prev, cur) => prev + cur.quantity, 0)
}

const getNewCart = (
	part: SparePart,
	quantity: number,
	items: NewPartRequestRowEntry[] = [],
	vesselId = ''
) => {
	const response = [...items]

	// Remove item
	if (quantity === 0) {
		return response.filter(
			(item: NewPartRequestRowEntry) => item.part.id !== part.id
		) as NewPartRequestRowEntry[]
	}

	// Update
	const existingItem = response.find(
		(item: NewPartRequestRowEntry) => item.part.id === part.id
	)
	// NOTE: To avoid unecessary infinite loop re-renders -> We should always return copy of the old object we're mutating (see React best practices)
	if (existingItem) {
		return items.map((item: NewPartRequestRowEntry) =>
			item.part.id === part.id ? { ...item, quantity } : { ...item }
		)
		// Add to list
	}
	response.push({
		id: part.id,
		part,
		quantity,
		vesselId
	})

	return response
}

export const NewPartRequestCartStore =
	createContext<NewPartRequestStoreProps>(null)

export const useNewPartRequestCartContext = () => {
	return useContext(NewPartRequestCartStore)
}

const NewPartRequestCartProvider = ({ children }) => {
	const router = useRouter()

	const [blok, setBlok] = useState<NewPartsTranslations>(null)
	const [items, setItems] = useState([] as NewPartRequestRowEntry[])
	const [{ count, status }, setNotificationStatus] = useState({
		count: 0,
		status: null
	})

	const addItem = useCallback(
		(item: SparePart, quantity = 1) => {
			const vesselId = (router.query?.id as string) ?? ''
			setItems((previous) =>
				getNewCart(item, quantity, previous, vesselId)
			)
			setNotificationStatus((previous) => ({
				count: previous.count + 1,
				status: NewPartRequestNotificatitonStatus.ADDED
			}))
		},
		[router, setNotificationStatus]
	)

	const removeItem = useCallback(
		(item: SparePart) => {
			setItems((previous) => getNewCart(item, 0, previous))
			setNotificationStatus((previous) => ({
				count: previous.count - 1,
				status: NewPartRequestNotificatitonStatus.REMOVED
			}))
		},
		[setNotificationStatus]
	)

	const updateQuantity = useCallback(
		(item: SparePart, quantity: number) => {
			const vesselId = (router.query?.id as string) ?? ''
			setItems((previous) =>
				getNewCart(item, quantity, previous, vesselId)
			)
			setNotificationStatus((previous) => ({
				count: previous.count + 1,
				status: NewPartRequestNotificatitonStatus.UPDATED
			}))
		},
		[router, setNotificationStatus]
	)

	const isItemInList = useCallback(
		(value: SparePart): boolean =>
			items.filter((item: NewPartRequestRowEntry) => item.id === value.id)
				?.length === 0,
		[items]
	)

	const toggleItem = useCallback(
		(value: SparePart) => {
			if (isItemInList(value)) {
				addItem(value, 1)
			} else {
				removeItem(value)
			}
		},
		[addItem, isItemInList, removeItem]
	)

	const clearItems = useCallback(() => {
		setItems([])
	}, [setItems])

	const value = useMemo(
		() => ({
			blok,
			clearItems,
			count,
			items,
			status,
			addItem,
			isItemInList,
			removeItem,
			setBlok,
			toggleItem,
			updateQuantity
		}),
		[
			blok,
			clearItems,
			count,
			items,
			status,
			addItem,
			isItemInList,
			removeItem,
			setBlok,
			toggleItem,
			updateQuantity
		]
	)

	return (
		<NewPartRequestCartStore.Provider value={value}>
			{children}
		</NewPartRequestCartStore.Provider>
	)
}

export default NewPartRequestCartProvider
