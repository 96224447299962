import { ReactNode } from 'react'

import { SnackbarNotificationTypes } from '@components/UI/Snackbar/types'

export enum NotificationTypes {
	SNACKBAR = 'snackbar'
}

export interface NotificationSnackbarProps {
	snackbarType: SnackbarNotificationTypes
	title: string
}

export interface NotificationOptions {
	showAfterDelay?: number
	hideAfterDelay?: number
	autoHide?: boolean
}

export interface NotificationContextProps {
	sendNotification?: (
		notificationType: NotificationTypes,
		message: NotificationSnackbarProps | unknown,
		options?: NotificationOptions | unknown
	) => void
	notification?: {
		count: number
		delayToShow?: number
		notificationType?: NotificationTypes
		message: NotificationSnackbarProps | unknown
		options?: NotificationOptions | unknown
	}
}

export interface NotificationProviderProps {
	children?: ReactNode | ReactNode[]
}
