import styled from 'styled-components'
import { theme } from '@damen/ui'

import ProfilePicture from '@components/UI/ProfilePicture'
import { ContactDetailsVariant } from './types'

export const ContactDetailsName = styled.h4`
	font-size: 14px;
	line-height: 1.14;
	letter-spacing: 0.7px;
	margin: 0 0 8px;
`

export const ContactDetailsTitle = styled.p`
	margin: 0;
	font-size: 14px;
	line-height: 30px;
	letter-spacing: 0.2px;
`

export const ContactDetailsImage = styled(ProfilePicture)`
	flex-grow: 0;
	flex-shrink: 0;
`

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin: 20px 0;

	@media ${theme.legacyMediaQueries.md} {
		display: inline-flex;
		width: 50%;
	}
`

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	margin-left: 32px;
	justify-content: center;

	button {
		font-size: 14px;
		line-height: 14px;
	}
`

export const ContactDetailsContainer = styled.div.withConfig({
	shouldForwardProp: (prop) => !['variant'].includes(prop)
})<{ variant: ContactDetailsVariant }>`
	display: flex;
	flex-direction: row;
	padding: 32px 0 27px;
	position: relative;

	&::after {
		content: '';
		width: 100%;
		position: absolute;
		bottom: 0;
		height: 1px;
		display: block;
		background-color: ${theme.colors.greyAccentDark};
		opacity: 0.15;
	}

	&:last-of-type {
		&::after {
			display: none;
		}
	}

	${({ variant }) =>
		variant === 'imageless' &&
		`
        ${Body} {
            margin-left: 0;
        }
        `}
`
