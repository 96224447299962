import styled from 'styled-components'

export const LoaderWrapper = styled.div`
	position: fixed;
	display: block;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	z-index: 2;

	svg {
		animation: spinning 1.4s infinite linear;
	}

	@keyframes spinning {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`

export const LoaderInline = styled.div`
	z-index: 2;

	svg {
		animation: spinning 1.4s infinite linear;
	}

	@keyframes spinning {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`
