import React, { FC, useCallback, useState } from 'react'
import { useRouter } from 'next/router'

import { Button, Icon, theme } from '@damen/ui'

import Loader from '@components/UI/Loader'
import AlertModal from '@components/Modals/AlertModal'

import { SERVICEREQUESTS } from '@src/constants/Routes'

import { AssetButtonTypes } from '@components/Modals/AlertModal/types'
import usePromptOnBeforeUnload from '@hooks/usePromptOnBeforeUnload'
import { FormButtonRightCol, FormButtonWrapper, LoaderWrapper } from './styles'
import { FormFooterProps } from './types'

const FormFooter: FC<FormFooterProps> = ({
	discardBody,
	discardTitle = '[Discard this request?]',
	labelCancel = '[No, stay on page]',
	labelConfirm = '[Yes, discard]',
	labelDiscard,
	labelNextStep = '[Next step]',
	labelSubmit = '[Submit]',
	onBack,
	isEnabled = true,
	isLastStep,
	isSending,
	promptDiscardAlert = false,
	routeOnClose = SERVICEREQUESTS,
	submitColor = 'orange'
}) => {
	const [isDiscardOpen, setIsDiscardOpen] = useState(false)
	const [enableBrowserPrompt, setEnableBrowserPrompt] =
		useState(promptDiscardAlert)

	usePromptOnBeforeUnload({
		enabled: enableBrowserPrompt,
		title: discardTitle ?? ''
	})

	const router = useRouter()

	const backButtonHandler = useCallback(() => {
		if (promptDiscardAlert) {
			setIsDiscardOpen(true)
		} else if (onBack) {
			onBack()
		} else {
			router.push(routeOnClose)
		}
	}, [router, onBack, promptDiscardAlert, routeOnClose, setIsDiscardOpen])

	const onModalClose = useCallback(
		(type: AssetButtonTypes) => {
			setIsDiscardOpen(false)

			if (type === AssetButtonTypes.CONFIRM) {
				setEnableBrowserPrompt(false)

				if (onBack) {
					onBack()
				} else {
					router.push(routeOnClose)
				}
			}
		},
		[onBack, routeOnClose, router, setEnableBrowserPrompt, setIsDiscardOpen]
	)

	const disabled = !isEnabled || (isEnabled && isSending)

	return (
		<>
			<FormButtonWrapper>
				{labelDiscard && (
					<Button.Default
						type="button"
						disabled={onBack ? false : disabled}
						text={labelDiscard}
						icon={Icon.LeftChevron}
						buttonType="text"
						colorType="blue"
						onClick={backButtonHandler}
					/>
				)}
				<FormButtonRightCol>
					<Button.Default
						disabled={disabled}
						type="submit"
						text={isLastStep ? labelSubmit : labelNextStep}
						icon={Icon.LeftChevron}
						colorType={submitColor}
						onClick={() => {}}
					/>
				</FormButtonRightCol>
			</FormButtonWrapper>
			<LoaderWrapper show={isSending}>
				<Loader fill={theme.colors.blue} inline />
			</LoaderWrapper>
			<AlertModal
				body={discardBody}
				isOpen={isDiscardOpen}
				labelCancel={labelCancel}
				labelConfirm={labelConfirm}
				onClose={onModalClose}
				title={discardTitle}
			/>
		</>
	)
}

export default FormFooter
