import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'

import { useNotificationContext } from '@contexts/NotificationContext'

import {
	NotificationTypes,
	NotificationOptions
} from '@contexts/NotificationContext/types'
import Snackbar from '@components/UI/Snackbar'
import { wait } from '@utils/wait'

const ComponentLookup = {
	[NotificationTypes.SNACKBAR]: Snackbar
}

const RenderNotification = (type: NotificationTypes, props) =>
	ComponentLookup[type]
		? React.createElement(ComponentLookup[type], props)
		: null

const Notifications: React.FC = () => {
	// Local state
	const [root, setRoot] = useState<HTMLElement>()
	const [open, setIsOpen] = useState(false)

	// Global state
	const {
		notification: { count, notificationType, message, options }
	} = useNotificationContext()

	// Event handlers
	const closeNotificationHandler = useCallback(() => {
		setIsOpen(false)
	}, [setIsOpen])

	const notificationCountChange = useCallback(async () => {
		if (count > 0) {
			const localOptions: NotificationOptions =
				options as NotificationOptions

			await wait(localOptions?.showAfterDelay || 10)

			setIsOpen(true)

			if (localOptions.autoHide && localOptions.hideAfterDelay > 0) {
				await wait(localOptions.hideAfterDelay)

				closeNotificationHandler()
			}
		}
	}, [closeNotificationHandler, count, options])

	// onChange (using increment to trigger a new notification regardless if it's open)
	useEffect(() => {
		notificationCountChange()
	}, [notificationCountChange])

	// onMount
	useEffect(() => {
		setRoot(document.getElementById('__next'))
	}, [setRoot])

	//
	// Cache properties to pass into RenderNotification method
	const notificationProps = useMemo(() => {
		return message
			? {
					open,
					onClose: closeNotificationHandler,
					...(message as any)
			  }
			: { open, message: null }
	}, [closeNotificationHandler, message, open])

	//
	// Render
	if (!root) return null

	return ReactDOM.createPortal(
		<>{RenderNotification(notificationType, notificationProps)}</>,
		root
	)
}

export default Notifications
