import React, { FC, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { Icon, theme } from '@damen/ui'

import { NEW_PARTREQUEST } from '@src/constants/Routes'

import { useNewPartRequestCartContext } from '@contexts/NewPartRequestCartContext'

import { Item, IconCount } from '@components/NavigationBar/styles'
import {
	Inner,
	Wrapper
} from '@components/NavigationBar/components/SupportButton/styles'

import QuotationRequestNotification from '../QuotationRequestNotification'

import { QuotationRequestButtonProps } from './types'
import { TableAndDesktopText } from './styles'

const variants = {
	visible: { opacity: [0, 1], scale: [0, 1] },
	hidden: { opacity: 0, scale: 1 }
}

const QuotationNotificatiton: FC<QuotationRequestButtonProps> = ({
	color,
	isLoading,
	translations
}) => {
	// Local state
	const [isCountVisible, setIsCountVisible] = useState(false)

	// Hooks
	const router = useRouter()
	const { count, items, status } = useNewPartRequestCartContext()

	// Event handlers
	const buttonClickHandler = useCallback(() => {
		router.push(NEW_PARTREQUEST)
	}, [router])

	// On prop/state change
	useEffect(() => {
		setIsCountVisible(false)
		requestAnimationFrame(() => setIsCountVisible(true))
	}, [count, setIsCountVisible])

	if (isLoading || router.asPath === NEW_PARTREQUEST) {
		return null
	}

	const itemLength = items?.length
	const maxItems = itemLength > 9
	const durationWrapper = theme.timing.numDefault / 1000
	const durationCount = theme.timing.numFast / 1000

	return (
		<Item>
			<Wrapper
				animate={{ width: itemLength === 0 ? 0 : 'auto' }}
				initial={{ width: 0 }}
				onClick={buttonClickHandler}
				transition={{ duration: durationWrapper }}
			>
				<Inner>
					<Icon.ShoppingCart width={20} height={20} fill={color} />
					<IconCount
						key={count}
						animate={isCountVisible ? 'visible' : 'hidden'}
						initial="hidden"
						transition={{ duration: durationCount }}
						variants={variants}
					>
						{maxItems ? 9 : itemLength}
						{maxItems && <sup>+</sup>}
					</IconCount>
					<TableAndDesktopText color={color}>
						{translations?.quotationButtonLabel ??
							'[Quotation request]'}
					</TableAndDesktopText>
				</Inner>
			</Wrapper>
			<QuotationRequestNotification count={count} status={status} />
		</Item>
	)
}

export default QuotationNotificatiton
