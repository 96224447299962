import { NextRouter } from 'next/router'

export const getQueryParamsForPath = (href: string, router: NextRouter) => {
	// Get the route parameters from the pathname
	// href: /my-fleet/[id]/ => ['[id]']
	const routeParams = href?.match(/\[([^\]]+)]/g)

	// Get the query params from the router
	// {id: "123", slug: "my-fleet"}
	const queryObj = { ...router.query }

	// Return only the query params if they are part of the route parameters
	// Slug is not part of the route parameters, so it will be removed to prevent it being added to the url as a query => ?slug=my-fleet
	// returns {id: "123"}
	return routeParams
		? Object.fromEntries(
				Object.entries(queryObj).filter(([key]) =>
					routeParams.includes(`[${key}]`)
				)
		  )
		: {}
}
