import React from 'react'
import SkeletonLoader from '@components/UI/SkeletonLoader'

import { useGetWeatherQuery } from '@graphql/graphql'

import { theme } from '@damen/ui'

import { useHeaderContext } from '@contexts/HeaderContext'
import { useLocale } from '@hooks/useLocale'
import { useTranslationContext } from '@contexts/TranslationContext'

import ContactModal from '@components/Modals/ContactModal'
import Clock from './components/Clock'
import QuotationRequestButton from './components/QuotationRequestButton'
import SupportButton from './components/SupportButton'
import Weather from './components/Weather'
import Logo from './components/Logo'

import { Item, ItemImage, List, Wrapper } from './styles'
import { NavigationBarProps } from './types'

const NavigationBar = ({
	headerColor,
	logoSrc,
	showLogo
}: NavigationBarProps) => {
	// Contexts
	const { locale } = useLocale()
	const { showNavigationBar, supportOpened, setSupportOpened } =
		useHeaderContext()
	const { translations } = useTranslationContext()

	// Local state
	const { data, loading, error } = useGetWeatherQuery({
		initialFetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-only'
	})

	const color =
		headerColor === 'white' || headerColor === 'transparent'
			? theme.colors.blueMarine
			: theme.colors.white

	const style = { opacity: color === 'white' ? 1 : 0.15, margin: 'auto 20px' }

	// APIs

	return (
		<>
			<ContactModal
				isOpen={supportOpened}
				onClose={() => setSupportOpened(false)}
			/>
			<Wrapper
				animate={{ opacity: showNavigationBar ? 1 : 0 }}
				transition={{
					duration: showNavigationBar
						? theme.timing.numDefault / 1000
						: 0
				}}
			>
				<List>
					<Item>
						<Clock locale={locale} color={color} />
					</Item>

					{!error && (
						<Item>
							{loading || !data?.currentWeather ? (
								<SkeletonLoader
									style={style}
									width={60}
									height={24}
								/>
							) : (
								<Weather
									color={color}
									icon={data.currentWeather.icon}
									value={data.currentWeather.value}
								/>
							)}
						</Item>
					)}

					<QuotationRequestButton
						color={color}
						isLoading={loading}
						translations={translations}
					/>
					<Item>
						{loading ? (
							<SkeletonLoader
								style={style}
								width={81}
								height={24}
							/>
						) : (
							<SupportButton
								color={color}
								label={
									translations?.supportLabel ?? '[Support]'
								}
							/>
						)}
					</Item>
					{logoSrc && showLogo && (
						<ItemImage
							animate={{
								width: showLogo ? 'auto' : 0,
								x: showLogo ? 0 : 24
							}}
							headerColor={headerColor}
							transition={{
								duration: theme.timing.numDefault / 1000
							}}
						>
							<Logo imageSrc={logoSrc} />
						</ItemImage>
					)}
				</List>
			</Wrapper>
		</>
	)
}

export default NavigationBar
