import { theme } from '@damen/ui'
import styled from 'styled-components'

export const ListItem = styled.li`
	display: flex;
	align-items: center;

	a {
		color: ${theme.colors.white};
		text-decoration: none;
		font-weight: 500;
		font-size: ${theme.typography.fontSizeText}px;
		line-height: 1;
		position: relative;
		display: inline-block;
		outline: none;

		&::before {
			content: '';
			width: 100%;
			height: 2px;
			background-color: ${theme.colors.white};
			position: absolute;
			bottom: -4px;
			left: 0;
			transform: scale3d(0, 1, 1);
			transform-origin: 100% 50%;
			transition: transform 400ms ease 0s;
		}

		&:focus,
		&:hover {
			&::before {
				transform: scale3d(1, 1, 1);
				transform-origin: 0% 50%;
			}
		}
	}

	svg {
		margin-right: 16px;
	}

	&:not(:last-child) {
		margin-bottom: 40px;
	}
`

export const StyledButton = styled.button`
	padding: 0;
	background: transparent;
	border: none;
	color: ${theme.colors.white};
	font-weight: 500;
	font-size: ${theme.typography.fontSizeText}px;
	line-height: 1;
	position: relative;
	display: inline-block;
	cursor: pointer;
	outline: none;

	&::before {
		content: '';
		width: 100%;
		height: 2px;
		background-color: ${theme.colors.white};
		position: absolute;
		bottom: -4px;
		left: 0;
		transform: scale3d(0, 1, 1);
		transform-origin: 100% 50%;
		transition: transform 400ms ease 0s;
	}

	&:focus,
	&:hover {
		&::before {
			transform: scale3d(1, 1, 1);
			transform-origin: 0% 50%;
		}
	}
`

export const Badge = styled.span``
