import { FileUploadFileReference } from '@components/UI/FileUpload/types'
import { ErrorCode } from 'react-dropzone'

const url =
	process.env.NEXT_PUBLIC_PROXY_ENDPOINT ??
	process.env.NEXT_PUBLIC_API_ENDPOINT_UPLOAD
export const postFile = async (file: File) => {
	const data = new FormData()
	data.append('file', file)

	const response = await fetch(url, {
		method: 'POST',
		body: data
	})

	if (!response.ok) {
		if (response.status === 415) {
			throw new Error(ErrorCode.FileInvalidType)
		}
		throw new Error('Failed to upload file')
	}
	const responseObject: FileUploadFileReference = await response.json()
	return responseObject
}
