import { useMemo } from 'react'
import { ApolloClient, makeVar, NormalizedCacheObject } from '@apollo/client'
import { createApolloClient } from './apollo-client'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject>

// This function should only be called from React
export const initializeApollo = (initialState = null) => {
	const currentApolloClient = apolloClient ?? createApolloClient()

	// If your page has Next.js data fetching methods that use Apollo Client, the initial state
	// gets hydrated here
	if (initialState) {
		// Get existing cache, loaded during client side data fetching
		const existingCache = currentApolloClient.extract()

		currentApolloClient.cache.restore({ ...existingCache, ...initialState })
	}
	// For SSG and SSR always create a new Apollo Client
	if (typeof window === 'undefined') {
		return currentApolloClient
	}
	// Create the Apollo Client once in the client
	if (!apolloClient) {
		apolloClient = currentApolloClient
	}

	return currentApolloClient
}

export const addApolloState = (
	client: ApolloClient<NormalizedCacheObject>,
	pageProps: any
) => {
	if (pageProps?.props) {
		pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
	}

	return pageProps
}

export const useApollo = (pageProps: any) => {
	const state = pageProps[APOLLO_STATE_PROP_NAME]
	const store = useMemo(() => initializeApollo(state), [state])

	return store
}

export const seenModalBefore = makeVar<boolean>(false)
export const toggleNav = makeVar<boolean>(false)
