export const containerVariants1 = {
	initial: {
		y: '0%'
	},
	animate: {
		y: '0%'
	},
	exit: {
		y: '109%',
		transition: {
			duration: 0.7,
			ease: 'easeInOut'
		}
	}
}

export const containerVariants2 = {
	initial: {
		y: '0%'
	},
	animate: {
		y: '0%'
	},
	exit: {
		y: '108%',
		transition: {
			duration: 0.9,
			ease: 'easeInOut'
		}
	}
}
