import styled from 'styled-components'
import { theme } from '@damen/ui'
import { WrapperProps } from './types'

export const ComponentWrapper = styled.div<WrapperProps>`
	border-bottom: ${({ borderBottom }) =>
		borderBottom === true
			? `1px solid ${theme.colors.greyAccentLight}`
			: 'none'};
	padding-bottom: ${({ paddingBottom }) =>
		paddingBottom === true ? '24px' : '0'};
	padding-top: ${({ paddingTop }) => (paddingTop === true ? '24px' : '0')};

	@media ${() => theme.legacyMediaQueries.sm} {
		padding-bottom: ${({ paddingBottom }) =>
			paddingBottom === true ? '40px' : '0'};
		padding-top: ${({ paddingTop }) =>
			paddingTop === true ? '40px' : '0'};
	}
`
