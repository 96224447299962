import React from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import { Icon, theme } from '@damen/ui'

import { LOADER } from '@src/constants/zIndexes'

import { containerVariants1, containerVariants2 } from './consts'

interface Props {
	show: boolean
}

const Loading: React.FC<Props> = ({ show }) => (
	<AnimatePresence>
		{show && (
			<>
				<Foreground
					variants={containerVariants1}
					key="child"
					exit="exit"
					initial="initial"
					animate="animate"
				>
					<Logo
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<Icon.LogoDamen fill={theme.colors.white} />
					</Logo>
					<FgInner
						initial={{ borderRadius: '50%' }}
						exit={{
							borderRadius: '0%',
							transition: { duration: 0.4, delay: 0.3 }
						}}
					/>
				</Foreground>
				<Background
					variants={containerVariants2}
					exit="exit"
					initial="initial"
					animate="animate"
				>
					<BgInner
						initial={{ borderRadius: '50%' }}
						exit={{
							borderRadius: '0%',
							transition: { duration: 0.5, delay: 0.3 }
						}}
					/>
				</Background>
			</>
		)}
	</AnimatePresence>
)

const Foreground = styled(motion.div)`
	display: flex;
	z-index: ${LOADER};
	position: fixed;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	transform: scale(1.5);
	top: 0;
	left: 0;
	background: ${theme.colors.blue};

	svg,
	g,
	path {
		fill: ${theme.colors.white};
	}
`

const FgInner = styled(motion.div)`
	position: absolute;
	margin: 0;
	padding: 0;
	border-radius: 0%;
	box-shadow: 0 0 0 300px ${theme.colors.blue};
	top: 0;
	left: 50%;
	transform: translate(-50%, -110%);
	width: 200%;
	height: 100%;
`

const Logo = styled(motion.div)`
	position: relative;
	z-index: 1;
`

const Background = styled(motion.div)`
	background: ${theme.colors.marineBlack};
	height: 100vh;
	width: 100vw;
	z-index: ${LOADER - 1};
	position: fixed;
`

const BgInner = styled(motion.div)`
	position: absolute;
	margin: 0;
	padding: 0;
	border-radius: 0%;
	box-shadow: 0 0 0 300px ${theme.colors.marineBlack};
	top: 0;
	left: 50%;
	transform: translate(-50%, -110%);
	width: 200%;
	height: 100%;
`

export default Loading
