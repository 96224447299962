import styled, { css } from 'styled-components'

import { theme } from '@damen/ui'

import {
	SnackbarIconProps,
	SnackbarNotificationsProps,
	SnackbarNotificationTypes,
	SnackbarWrapperProps
} from '@components/UI/Snackbar/types'

import { SNACKBAR } from '@src/constants/zIndexes'

import { hexToRgb } from '@utils/helper'

const snackbarThemes: SnackbarNotificationsProps = {
	[SnackbarNotificationTypes.SUCCESS]: {
		backgroundColor: theme.colors.greenLight,
		iconColor: theme.colors.green
	},
	[SnackbarNotificationTypes.ERROR]: {
		backgroundColor: theme.colors.redLight,
		iconColor: theme.colors.red
	},
	[SnackbarNotificationTypes.WARNING]: {
		// lightYellow color that is not available in the theme
		backgroundColor: '#f5f1d4',
		iconColor: theme.colors.yellow
	}
}

export const Wrapper = styled.div<SnackbarWrapperProps>`
	will-change: opacity;
	position: fixed;
	top: 55px;
	left: 50%;
	right: auto;
	width: 80%;
	max-width: 790px;
	display: flex;
	flex-direction: row;
	padding: 24px 32px;
	z-index: ${SNACKBAR};
	align-items: center;
	box-shadow: 10px 20px 40px 0
		${hexToRgb(theme.colors.marineBlack, {
			alpha: 0.1,
			format: 'css'
		})};
	transition: all 300ms ease-in-out;
	transform: translate3d(-50%, 30px, 0);
	opacity: 0;
	pointer-events: none;
	background-color: ${({ snackbarType }) =>
		css`
			${snackbarThemes[snackbarType].backgroundColor}
		`};

	${({ isOpen }) =>
		isOpen &&
		css`
			opacity: 1;
			pointer-events: auto;
			transform: translate3d(-50%, 0, 0);
		`}
`

export const SnackbarIcon = styled.span<SnackbarIconProps>`
	width: 32px;
	height: 32px;
	flex-grow: 0;
	flex-shrink: 0;
	border-radius: 100%;
	overflow: hidden;
	margin-right: 24px;
	display: flex;
	align-items: center;
	justify-content: center;

	${({ snackbarType }) => css`
		background-color: ${snackbarThemes[snackbarType].iconColor};
	`}
`

export const Body = styled.p`
	margin: 0 16px 0 0;
	font-size: 14px;
	line-height: 30px;
	letter-spacing: 0.2px;
	color: ${theme.colors.marineBlack};
	flex-grow: 1;
	flex-shrink: 1;
`

export const CloseButton = styled.button`
	cursor: pointer;
	border: none;
	background-color: transparent;
	padding: 8px 11px;
	flex-grow: 0;
	flex-shrink: 0;
`
