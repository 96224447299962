import styled from 'styled-components'

import { theme } from '@damen/ui'
import { MOBILE, TABLET, DESKTOP } from '@src/constants/Viewports'

import { MenuItemProps } from './types'

export const MenuItem = styled.li<MenuItemProps>`
	@media ${theme.legacyMediaQueries.mdMax} {
		display: ${({ visibility }) =>
			visibility.indexOf(MOBILE) === -1 ? 'none' : 'block'};
	}

	@media ${theme.legacyMediaQueries.md} {
		display: ${({ visibility }) =>
			visibility.indexOf(TABLET) === -1 ? 'none' : 'block'};
	}

	@media ${theme.legacyMediaQueries.lg} {
		display: ${({ visibility }) =>
			visibility.indexOf(DESKTOP) === -1 ? 'none' : 'block'};
	}
`
