import { ContactInfoProps } from './components/ContactInfo/types'

export const mapContent = (data: any, attachments: any): any => {
	const formContent = {
		generalInformation: data?.contactTitle || '[Ask any question]',
		stateSaving: data?.stateSaving || '[Saving...]',
		buttonSubmit: data?.labelContactButton || '[Send your question]',
		buttonBack: null,
		generalTitle: data?.contactTitle || '[Ask any question]',
		generalDescription: data?.generalDescription,
		labelGeneralCompany: data?.labelContactCompany || '[Company]',
		placeholderGeneralCompany:
			data?.placeholderGeneralCompany || '[Company]',
		labelGeneralYourReference:
			data?.labelContactYourReference || '[Your reference]',
		placeholderGeneralYourReference:
			data?.placeholderContactYourReference || '[Reference]',
		labelGeneralSubject: data?.labelContactSubject || '[Subject]',
		placeholderGeneralSubject:
			data?.placeholderContactSubject || '[Subject]',
		labelGeneralQuestion:
			data?.labelContactQuestion || '[Your request or question]',
		placeholderGeneralQuestion:
			data?.placeholderContactQuestion || '[Hello, ...]',
		labelGeneralAttachments:
			data?.labelGeneralAttachments || '[Attachments]',
		submitSuccess: data?.submitSuccess || '[Your email was sent]',
		submitError:
			data?.submitError || '[There was an error. Please try again later]',
		attachments: attachments?.content?.global[0],
		errorDescription:
			data?.errorDescription ||
			'[Something went wrong while retrieving the form data.]'
	}

	const contactContent: ContactInfoProps = {
		title: data?.generalTitle || '[Urgent service]',
		phoneNumber: data?.generalPhoneNumber || '[+31 183 53 36 27]',
		supportTitle: data?.generalSupportTitle || '[Support team]',
		labelContact: data?.labelGeneralContact || '[Contact]',
		errorContactsDescription: data?.errorContactsDescription
	}

	return {
		formContent,
		contactContent,
		validation: data?.validation[0]?.messages[0]
	}
}
