import styled from 'styled-components'

import { theme } from '@damen/ui'

import { hexToRgb } from '@utils/helper'

import { ElementProps } from './types'

export const Wrapper = styled.div<ElementProps>`
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: inherit;
	position: relative;

	> form {
		display: flex;
		flex-direction: column;
		height: inherit;
		flex-grow: 1;
		flex-shrink: 1;
		position: relative;
	}

	&::before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 20px;
		background: linear-gradient(
			180deg,
			${hexToRgb(theme.colors.white, {
					alpha: 1,
					format: 'css'
				})}
				0%,
			${hexToRgb(theme.colors.white, {
					alpha: 0,
					format: 'css'
				})}
				100%
		);
		top: 0;
		z-index: 1;
	}

	@media ${theme.legacyMediaQueries.md} {
		&::before {
			height: 40px;
		}
	}
`

export const Content = styled.div<ElementProps>`
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: auto;
	padding: ${({ isNarrow }) => (isNarrow ? '20px' : '10px 32px 20px')};
	position: relative;

	@media ${theme.legacyMediaQueries.md} {
		padding: ${({ isNarrow }) =>
			isNarrow ? '20px 40px 40px' : '20px 64px 40px'};
	}
`

export const Footer = styled.div<ElementProps>`
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	padding: 20px;
	position: relative;
	min-height: 88px;
	justify-content: ${({ hasSingleButton }) =>
		hasSingleButton ? 'flex-end' : 'auto'};

	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		left: 20px;
		right: 20px;
		top: 0;
	}

	&::before {
		height: 20px;
		background: linear-gradient(
			180deg,
			${hexToRgb(theme.colors.white, {
					alpha: 0,
					format: 'css'
				})}
				0%,
			${hexToRgb(theme.colors.white, {
					alpha: 1,
					format: 'css'
				})}
				100%
		);
		top: -20px;
		z-index: 1;
	}

	&::after {
		background: ${theme.colors.greyAccentLight};
		height: 1px;
		z-index: 2;
	}

	@media ${theme.legacyMediaQueries.md} {
		padding: ${({ isNarrow }) => (isNarrow ? '32px 40px' : '32px 64px')};
		min-height: 112px;

		&::before {
			height: 40px;
			top: -40px;
		}

		&::before,
		&::after {
			left: ${({ isNarrow }) => (isNarrow ? '40px' : '64px')};
			right: ${({ isNarrow }) => (isNarrow ? '40px' : '64px')};
		}
	}
`
