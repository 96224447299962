import React, { FC, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { Icon, theme } from '@damen/ui'

import { NEW_PARTREQUEST } from '@src/constants/Routes'

import { useNewPartRequestCartContext } from '@contexts/NewPartRequestCartContext'

import { IconCount } from '@components/NavigationBar/styles'

import { Button } from './styles'
import { MobileQuotationRequestButtonProps } from './types'

const variants = {
	visible: { opacity: [0, 1], scale: [0, 1] },
	hidden: { opacity: 0, scale: 1 }
}

const QuotationNotificatiton: FC<MobileQuotationRequestButtonProps> = ({
	color,
	iconSize
}) => {
	// Local state
	const [isCountVisible, setIsCountVisible] = useState(false)

	// Hooks
	const router = useRouter()
	const { count, items } = useNewPartRequestCartContext()

	// Event handlers
	const buttonClickHandler = useCallback(() => {
		router.push(NEW_PARTREQUEST)
	}, [router])

	// On prop/state change
	useEffect(() => {
		setIsCountVisible(false)
		requestAnimationFrame(() => setIsCountVisible(true))
	}, [count, setIsCountVisible])

	if (router.asPath === NEW_PARTREQUEST) {
		return null
	}

	const itemLength = items?.length
	const maxItems = itemLength > 9
	const durationCount = theme.timing.numFast / 1000

	if (count === 0) {
		return null
	}

	return (
		<Button onClick={buttonClickHandler} type="button">
			<Icon.ShoppingCart
				width={iconSize}
				height={iconSize}
				fill={color}
			/>
			<IconCount
				key={count}
				animate={isCountVisible ? 'visible' : 'hidden'}
				initial="hidden"
				transition={{ duration: durationCount }}
				variants={variants}
			>
				{maxItems ? 9 : itemLength}
				{maxItems && <sup>+</sup>}
			</IconCount>
		</Button>
	)
}

export default QuotationNotificatiton
