import { ParentLink } from '@graphql/graphql'
import React, {
	ReactNode,
	useState,
	MouseEvent,
	Dispatch,
	SetStateAction,
	useMemo,
	useContext
} from 'react'

type AvailableColors = 'white' | 'blue' | 'transparent' | 'marineBlack'

export interface HeaderContextProps {
	headerColor: AvailableColors
	setHeaderColor?: Dispatch<SetStateAction<string>>
	logoSrc?: string
	setLogoSrc?: Dispatch<SetStateAction<string>>
	menuOpened: boolean
	menuToggle?: (() => void) | null | ((event: MouseEvent) => void)
	parentLink?: ParentLink
	setParentLink?: Dispatch<SetStateAction<ParentLink | null>>
	showNavigationBar: boolean
	setShowNavigationBar?: Dispatch<SetStateAction<boolean>>
	supportOpened: boolean
	setSupportOpened?: Dispatch<SetStateAction<boolean>>
}

export interface HeaderProviderProps {
	// eslint-disable-next-line react/require-default-props
	children?: ReactNode
}

const HeaderContext = React.createContext<HeaderContextProps>({
	headerColor: 'white',
	logoSrc: null,
	menuOpened: false,
	showNavigationBar: true,
	supportOpened: false
})

export const useHeaderContext = () => useContext(HeaderContext)

export const HeaderProvider = ({ children }: HeaderProviderProps) => {
	const [headerColor, setHeaderColor] = useState<AvailableColors>('white')
	const [logoSrc, setLogoSrc] = useState<string>(null)
	const [menuOpened, setMenuOpened] = useState<boolean>(false)
	const [parentLink, setParentLink] = useState(null)
	const [showNavigationBar, setShowNavigationBar] = useState<boolean>(true)
	const [supportOpened, setSupportOpened] = useState<boolean>(false)

	const headerProviderValue = useMemo(() => {
		const menuToggle = () => {
			setMenuOpened(!menuOpened)
		}

		return {
			headerColor,
			setHeaderColor,
			logoSrc,
			setLogoSrc,
			menuOpened,
			menuToggle,
			parentLink,
			setParentLink,
			showNavigationBar,
			setShowNavigationBar,
			supportOpened,
			setSupportOpened
		}
	}, [
		headerColor,
		setHeaderColor,
		logoSrc,
		setLogoSrc,
		menuOpened,
		parentLink,
		setParentLink,
		showNavigationBar,
		setShowNavigationBar,
		supportOpened,
		setSupportOpened
	])

	return (
		<HeaderContext.Provider value={headerProviderValue}>
			{children}
		</HeaderContext.Provider>
	)
}

export default HeaderContext
