import React, { forwardRef } from 'react'

import {
	ProfilePictureWrapper,
	ProfilePictureImage,
	ProfilePictureNoImage
} from './styles'
import { ProfilePictureProps, ProfilePictureSize } from './types'

const ProfilePicture = forwardRef<HTMLDivElement, ProfilePictureProps>(
	(props, ref) => (
		<ProfilePictureWrapper
			ref={ref}
			{...props}
			size={props.size || ProfilePictureSize.DEFAULT}
		>
			{props.url ? (
				<ProfilePictureImage src={props.url} alt="" />
			) : (
				<ProfilePictureNoImage
					size={props.size || ProfilePictureSize.DEFAULT}
				/>
			)}
		</ProfilePictureWrapper>
	)
)

export default ProfilePicture
