import * as Yup from 'yup'
import { ValidationRegexes } from '@components/UI/Forms/Validation/rules'

export function noWhitespaceAndRequired(message: string) {
	return this.test({
		message,
		name: 'noWhitespaceAndRequired',
		test(value: string) {
			if (
				value === '' ||
				value === undefined ||
				!ValidationRegexes.notOnlyWhitespace.test(value)
			) {
				return false
			}
			return true
		}
	})
}

export function noWhitespace(message: string) {
	return this.test({
		message,
		name: 'noWhitespace',
		test(value: string) {
			if (!ValidationRegexes.notOnlyWhitespace.test(value)) {
				return false
			}
			return true
		}
	})
}

Yup.addMethod(Yup.string, 'noWhitespaceAndRequired', noWhitespaceAndRequired)
Yup.addMethod(Yup.string, 'noWhitespace', noWhitespace)

export default Yup
