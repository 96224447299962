/* eslint-disable global-require */
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import getConfig from 'next/config'

const ai = {
	reactPlugin: undefined,
	applicationInsights: undefined
}

const getInstrumentationKeyFromPublic = () =>
	getConfig().publicRuntimeConfig.APPINSIGHTS_INSTRUMENTATIONKEY

const createApplicationInsights = () => {
	if (ai.reactPlugin) return ai

	const reactPlugin = new ReactPlugin()
	const applicationInsights = new ApplicationInsights({
		config: {
			instrumentationKey:
				process.env.APPINSIGHTS_INSTRUMENTATIONKEY ??
				getInstrumentationKeyFromPublic() ??
				'default',
			extensions: [reactPlugin]
		}
	})
	applicationInsights.loadAppInsights()

	ai.reactPlugin = reactPlugin
	ai.applicationInsights = applicationInsights

	return ai
}

export { createApplicationInsights }
