import styled, { css } from 'styled-components'

import { theme } from '@damen/ui'

import { MENU, OVERLAY } from '@src/constants/zIndexes'
import { hexToRgb } from '@utils/helper'

import { MenuWrapperProps } from './types'

export const Wrapper = styled.div<MenuWrapperProps>`
	display: flex;
	flex-direction: column;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	padding: 25px;
	color: white;
	background-color: ${theme.colors.blue};
	box-shadow: 20px 40px 60px 0
		${hexToRgb(theme.colors.marineBlack, {
			alpha: 0.3,
			format: 'css'
		})};
	transform: translateX(calc(-100% - 60px));
	transition: transform 0.2s;
	box-sizing: border-box;
	z-index: ${MENU};
	overflow: auto;

	${({ isOpen }) =>
		isOpen &&
		css`
			transform: translateX(0);
		`}

	@media ${theme.legacyMediaQueries.sm} {
		max-width: 532px;
		padding: 40px;
	}
`

export const Overlay = styled.div<MenuWrapperProps>`
	cursor: pointer;
	pointer-events: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: transparent;
	transition: background 0.2s;
	z-index: ${OVERLAY};

	${({ isOpen }) =>
		isOpen &&
		css`
			pointer-events: auto;
			background-color: ${hexToRgb(theme.colors.marineBlack, {
				alpha: 0.75,
				format: 'css'
			})};
			cursor: pointer;
		`}
`

export const CloseButton = styled.button<MenuWrapperProps>`
	position: absolute;
	display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
	top: 22px;
	left: 17px;
	z-index: ${MENU + 1};
	cursor: pointer;
	border: 0;
	background: transparent;
	font-size: 36px;
	width: 32px;
	height: 32px;
	display: flex;
	color: white;
	outline: none;
	align-items: center;
	justify-content: center;

	svg {
		margin: auto;
	}

	&::before {
		content: '';
		background-color: ${theme.colors.white};
		opacity: 0.2;
		border-radius: 100%;
		width: 32px;
		height: 32px;
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		transform: scale3d(0, 0, 0);
		transition: transform 400ms ease 0s;
	}

	&:focus,
	&:hover {
		&::before {
			transform: scale3d(1, 1, 1);
		}
	}

	@media ${theme.legacyMediaQueries.md} {
		top: 31px;
		left: 32px;
	}
`

export const Header = styled.div`
	flex: 0 1 auto;

	button {
		font-size: 0;
	}
`

export const Body = styled.div`
	flex: 1 0 auto;
	padding: 56px 62px 47px 62px;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	a,
	button {
		display: block;
		padding: 14px 0;
		color: white;
		font-size: 30px;
		font-weight: bold;
		text-decoration: none;
		position: relative;
		display: inline-block;
		outline: none;
		border: 0;
		background-color: transparent;

		&::before {
			content: '';
			width: 100%;
			height: 4px;
			background-color: ${theme.colors.white};
			position: absolute;
			bottom: 15px;
			left: 0;
			transform: scale3d(0, 1, 1);
			transform-origin: 100% 50%;
			transition: transform 400ms ease 0s;
		}

		&:focus,
		&:hover {
			&::before {
				transform: scale3d(1, 1, 1);
				transform-origin: 0% 50%;
			}
		}
	}

	@media ${theme.legacyMediaQueries.sm} {
		padding: 110px 62px 47px 76px;
	}
`

export const Footer = styled.div`
	flex: 0 1 auto;
	padding: 0 62px 61px 62px;

	@media ${theme.legacyMediaQueries.sm} {
		padding: 0 62px 88px 75px;
	}
`
