import { useEffect } from 'react'

const useClickOutside = (
	ref: React.RefObject<HTMLElement>,
	action: () => void
) => {
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				action()
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [action, ref])
}
export default useClickOutside
