// Taken from https://stackoverflow.com/questions/30994015/regexp-to-check-if-file-is-image
const IS_IMAGE = /[/.](gif|jpg|jpeg|png)$/i
const IS_SPREADSHEET =
	/[/.](csv|vnd.ms-excel|vnd.openxmlformats-officedocument.spreadsheetml.sheet)$/i
const IS_DOCUMENT =
	/[/.](text|csv|vnd.ms-word|vnd.openxmlformats-officedocument.wordprocessingml.document)$/i

export const isPDF = (type: string) => type?.toLowerCase() === 'application/pdf'
export const isSpreadsheet = (type: string) =>
	IS_SPREADSHEET.test(type?.toLowerCase())
export const isImage = (type: string) => IS_IMAGE.test(type?.toLowerCase())
export const isDocument = (type: string) =>
	IS_DOCUMENT.test(type?.toLowerCase())
export const isArchive = (type: string) =>
	type?.toLowerCase() === 'application/zip'
