import React, { FC } from 'react'

import { FormHeaderElement, FormTitle } from './styles'
import { FormHeaderProps } from './types'

const FormHeader: FC<FormHeaderProps> = ({
	children,
	doubleSpacing = false,
	title
}) => (
	<FormHeaderElement doubleSpacing={doubleSpacing}>
		<FormTitle>{title}</FormTitle>
		{children}
	</FormHeaderElement>
)

export { FormTitle }

export default FormHeader
