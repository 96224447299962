import React, { FC, useMemo } from 'react'

import { Icon } from '@damen/ui'
import { SvgProps } from '@damen/ui/lib/cjs/types'

import { Wrapper, Text } from './styles'
import { WeatherProps } from './types'

const Weather: FC<WeatherProps> = ({ color, icon, value }) => {
	const WeatherIcon: FC<SvgProps> = useMemo(() => {
		switch (icon) {
			case 'Cloud':
				return Icon.Cloud
			case 'Clouds':
				return Icon.Clouds
			case 'CloudShowersHeavy':
				return Icon.CloudShowersHeavy
			case 'CloudSunRain':
				return Icon.CloudSunRain
			case 'Fog':
				return Icon.Fog
			case 'Snowflake':
				return Icon.Snowflake
			case 'Sun':
				return Icon.Sun
			case 'SunCloud':
				return Icon.SunCloud
			case 'Thunderstorm':
				return Icon.Thunderstorm
			default:
				return Icon.Cloud
		}
	}, [icon])

	return (
		<Wrapper>
			{WeatherIcon && <WeatherIcon width={20} height={20} fill={color} />}
			<Text color={color}>{value}</Text>
		</Wrapper>
	)
}

export default Weather
