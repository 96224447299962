import React, {
	useContext,
	useState,
	useCallback,
	useEffect,
	useMemo
} from 'react'
import { Formik } from 'formik'

import {
	useCreateNewRequestForInformationMutation,
	useGetUserCompaniesQuery
} from '@graphql/graphql'

import { NewRequestForInformationStore } from '@contexts/NewRequestForInformationContext'
import { validationSchema } from '@contexts/NewRequestForInformationContext/Validation'
import { useNotificationContext } from '@contexts/NotificationContext'
import { NotificationTypes } from '@contexts/NotificationContext/types'
import { FileState } from '@components/UI/FileUpload/Default/types'
import { FormState } from '@components/UI/Forms/types'

import FormFooter from '@components/UI/Forms/FormFooter'
import { SnackbarNotificationTypes } from '@components/UI/Snackbar/types'
import { Container, StyledSpacer } from '@components/UI/Forms/FormUI'
import { CardElement } from '@components/UI/Card/styles'
import {
	LabelWrapper,
	TitleWrapper
} from '@skeletons/FormStepperRequest/styles'
import SkeletonLoader from '@components/UI/SkeletonLoader'
import SidebarForm from '@components/SidebarForm'
import useError from '@hooks/useError'
import ErrorState from '@components/UI/Error'
import { ContactFormProps } from './types'

import GeneralFormFields from './components/GeneralFormFields'
import { mapValues } from './helpers/mapValues'

const ContactForm = ({
	content,
	validation,
	onFormSubmit
}: ContactFormProps) => {
	// Hooks
	const { showError } = useError()

	// Contexts
	const {
		initialValues,
		state: formState,
		setState
	} = useContext(NewRequestForInformationStore)
	const { sendNotification } = useNotificationContext()

	// Local state
	const [sending, setSending] = useState(false)
	const [footerEnabled, setFooterEnabled] = useState(true)

	// APIs
	const [createNewRequestForInformationMutation] =
		useCreateNewRequestForInformationMutation()

	const {
		data,
		loading: companiesLoading,
		error: companiesError
	} = useGetUserCompaniesQuery({ variables: { limit: 1_000 } })

	const companiesData = useMemo(
		() =>
			data?.companies?.items?.map((company) => ({
				label: company.name,
				value: company.id
			})),
		[data]
	)

	// Event handlers
	const submitHandler = useCallback(
		async (values) => {
			setSending(true)

			const formValues = mapValues(values)

			try {
				const request = await createNewRequestForInformationMutation({
					variables: {
						request: formValues
					}
				})

				if (request.data) {
					const id = request?.data?.createRequestForInformation.id

					if (id) {
						sendNotification(
							NotificationTypes.SNACKBAR,
							{
								snackbarType: SnackbarNotificationTypes.SUCCESS,
								title: content.submitSuccess
							},
							{
								showAfterDelay: 2000
							}
						)
					}

					onFormSubmit()
					setSending(false)
				}
			} catch (requestError) {
				setSending(false)

				showError(content?.submitError)
			}
		},
		[
			createNewRequestForInformationMutation,
			onFormSubmit,
			sendNotification,
			content?.submitSuccess,
			content?.submitError,
			showError
		]
	)

	const fileUploadingHandler = useCallback(() => {
		setFooterEnabled(false)
	}, [setFooterEnabled])

	const fileUploadCompleteHandler = useCallback(
		(state: FileState) => {
			setFooterEnabled(state === FileState.SUCCES)
		},
		[setFooterEnabled]
	)

	useEffect(() => {
		if (formState === FormState.complete) {
			setSending(false)
		}
	}, [formState, setSending])

	useEffect(() => {
		if (companiesError) {
			setState(FormState.error)
		} else {
			setState(FormState.inProgress)
		}
	}, [companiesError, setState])

	if (companiesError) {
		return (
			<StyledSpacer>
				<Container small>
					<ErrorState
						content={
							content?.errorDescription ??
							'Something went wrong while retrieving the form.'
						}
						showBowWave
					/>
				</Container>
			</StyledSpacer>
		)
	}

	if (companiesLoading) {
		return (
			<StyledSpacer>
				<Container small>
					<CardElement>
						<TitleWrapper>
							<SkeletonLoader width={100} height={20} />
							<SkeletonLoader width={200} height={10} />
						</TitleWrapper>
						<LabelWrapper>
							<SkeletonLoader width={100} />
						</LabelWrapper>
						<SkeletonLoader height={40} width={150} />
						<LabelWrapper>
							<SkeletonLoader width={100} height={20} />
						</LabelWrapper>
						<SkeletonLoader height={40} width={150} />
						<LabelWrapper>
							<SkeletonLoader width={100} height={20} />
						</LabelWrapper>
						<SkeletonLoader height={200} />
						<LabelWrapper>
							<SkeletonLoader width={100} height={20} />
						</LabelWrapper>
						<LabelWrapper>
							<SkeletonLoader width={100} height={10} />
						</LabelWrapper>
						<SkeletonLoader height={150} />
					</CardElement>
				</Container>
			</StyledSpacer>
		)
	}

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize
			onSubmit={submitHandler}
			validationSchema={validationSchema(validation, false)}
			validateOnChange={false}
			validateOnBlur={false}
		>
			{({ errors, handleSubmit, setFieldValue }) => {
				return (
					content && (
						<SidebarForm
							isNarrow
							footer={
								<FormFooter
									isNarrow
									labelDiscard={content?.buttonBack}
									labelSaving={content?.stateSaving}
									labelSubmit={content?.buttonSubmit}
									isSending={sending}
									isEnabled={footerEnabled}
									isLastStep
									submitColor="orange"
								/>
							}
							onSubmit={handleSubmit}
						>
							<GeneralFormFields
								companies={companiesData}
								content={content}
								errors={errors}
								onFileUploading={fileUploadingHandler}
								onFileUploadComplete={(state) =>
									fileUploadCompleteHandler(state)
								}
								setFieldValue={setFieldValue}
							/>
						</SidebarForm>
					)
				)
			}}
		</Formik>
	)
}

export default ContactForm
