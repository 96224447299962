import {
	FileReference,
	FileUploadFileReference
} from '@components/UI/FileUpload/types'

const parseFiles = (files: FileUploadFileReference[] = []) =>
	files.map((file: FileUploadFileReference) => {
		return { url: file.url } as FileReference
	})

export default parseFiles
