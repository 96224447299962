import styled from 'styled-components'
import { motion } from 'framer-motion'

import { theme } from '@damen/ui'

import { NewPartRequestNotificatitonStatus } from '@contexts/NewPartRequestCartContext/types'
import { MY_DAMEN_LIGHT_BLUE } from '@src/constants/Colors'
import { StatusProps } from './types'

const BackgroundColor = {
	[NewPartRequestNotificatitonStatus.ADDED]: theme.colors.greenLight,
	[NewPartRequestNotificatitonStatus.UPDATED]: theme.colors.blueLight,
	[NewPartRequestNotificatitonStatus.REMOVED]: theme.colors.blueLight
}

const IconColor = {
	[NewPartRequestNotificatitonStatus.ADDED]: theme.colors.green,
	[NewPartRequestNotificatitonStatus.UPDATED]: MY_DAMEN_LIGHT_BLUE,
	[NewPartRequestNotificatitonStatus.REMOVED]: MY_DAMEN_LIGHT_BLUE
}

export const Wrapper = styled(motion.div)<StatusProps>`
	position: fixed;
	display: flex;
	width: auto;
	height: 70px;
	min-width: 384px;
	background-color: ${({ status }) => BackgroundColor[status]};
	top: 70px;
	right: 128px;
	padding: 16px 32px;
	z-index: 4;

	&::before {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 10px 10px 10px;
		border-color: transparent transparent
			${({ status }) => BackgroundColor[status]} transparent;
		top: -10px;
		right: 80px;
	}
`

export const SnackbarIcon = styled.span<StatusProps>`
	width: 32px;
	height: 32px;
	flex-grow: 0;
	flex-shrink: 0;
	border-radius: 100%;
	overflow: hidden;
	margin: auto 24px auto 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ status }) => IconColor[status]};
`

export const Text = styled.p`
	color: ${theme.colors.marineBlack};
	flex-grow: 0;
	flex-shrink: 0;
	margin: auto 12px auto 0;
`

export const CloseButton = styled.button`
	width: 24px;
	height: 24px;
	border: 0;
	background-color: transparent;
	margin: auto 0 auto auto;
	padding-left: 16px;
	cursor: pointer;
`
