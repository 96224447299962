import * as React from 'react'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from './useApollo'

export interface AuthenticatedApolloProviderProps {
	pageProps: any
	children: React.ReactNode | React.ReactNode[] | null
}

const AuthenticatedApolloProvider = ({
	pageProps,
	children
}: AuthenticatedApolloProviderProps) => {
	const client = useApollo(pageProps)

	return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export { AuthenticatedApolloProvider }
