const stripEmpty = <T extends Record<string, unknown>>(obj: T): T => {
	Object.keys(obj).forEach((key) => {
		if (obj[key] === undefined || obj[key] === '' || obj[key] === null) {
			// eslint-disable-next-line no-param-reassign
			delete obj[key]
		}
	})
	return obj
}

export default stripEmpty
