import styled from 'styled-components'

import { theme } from '@damen/ui'

export const Button = styled.button`
	border: 0;
	right: 0;
	width: 66px;
	height: 66px;
	display: flex;
	background-color: transparent;
	border: 0;
	outline: none;
	padding: 0;
	cursor: pointer;

	svg {
		margin: auto;
	}

	@media ${theme.legacyMediaQueries.sm} {
		width: 80px;
		height: 80px;
	}
`
