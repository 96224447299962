export const HTTP_HEADER_CACHECONTROL = 'Cache-Control'
export const HTTP_HEADER_CACHECONTROL_NOSTORE = 'no-store'
export const HTTP_HEADER_XFUNCTIONSKEY = 'x-functions-key'
export const HTTP_HEADER_AUTHORIZATION = 'Authorization'
export const HTTP_HEADER_SETCOOKIE = 'set-cookie'
export const HTTP_HEADER_CONTENTTYPE = 'Content-Type'
export const HTTP_HEADER_CONTENTTYPE_WWWFORMURLENCODED =
	'application/x-www-form-urlencoded'
export const HTTP_HEADER_CONTENTTYPE_APPLICATIONJSON = 'application/json'

export const HTTP_STATUSCODE_SEEOTHER = 303
