import React, { FC, useEffect, useRef } from 'react'
import { Form } from 'formik'

import { Content, Footer, Wrapper } from './styles'
import { SidebarProps } from './types'

const SidebarForm: FC<React.PropsWithChildren<SidebarProps>> = ({
	children,
	footer,
	isNarrow = false,
	onSubmit
}) => {
	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (ref.current) {
			ref?.current?.scrollTo(0, 0)
		}
	}, [ref])

	return (
		<Wrapper isNarrow={isNarrow}>
			{onSubmit ? (
				<Form onSubmit={onSubmit}>
					<Content isNarrow={isNarrow} ref={ref}>
						{children}
					</Content>
					{footer && <Footer isNarrow={isNarrow}>{footer}</Footer>}
				</Form>
			) : (
				<>
					<Content isNarrow={isNarrow}>{children}</Content>
					{footer && <Footer isNarrow={isNarrow}>{footer}</Footer>}
				</>
			)}
		</Wrapper>
	)
}
export default SidebarForm
