import React from 'react'
import { Icon, theme } from '@damen/ui'

import { FileUploadFileReference, Mode } from '@components/UI/FileUpload/types'

import FileIcon from './FileIcon'

import { FileUploadListProps } from './types'

import {
	FileUploadListElement,
	FileUploadItem,
	FileUploadItemIcon,
	FileUploadItemLabel,
	FileUploadItemButton
} from './styles'

const FileUploadList: React.FC<FileUploadListProps> = ({
	disabled = false,
	items,
	mode = Mode.READ_AND_WRITE,
	noTopPadding = false,
	onDelete
}) => {
	return (
		<FileUploadListElement disabled={disabled} noTopPadding={noTopPadding}>
			{items.map((file: FileUploadFileReference, index: number) => {
				const key = `${file.name}-${index}`
				return (
					<FileUploadItem key={key}>
						<FileUploadItemIcon>
							<FileIcon
								type={file.type}
								fill={theme.colors.marineBlack}
							/>
						</FileUploadItemIcon>
						<FileUploadItemLabel>{file.name}</FileUploadItemLabel>
						<FileUploadItemButton
							as="a"
							href={file?.url}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Icon.Download
								width={18}
								height={18}
								fill={theme.colors.blue}
							/>
						</FileUploadItemButton>
						{mode === Mode.READ_AND_WRITE && (
							<FileUploadItemButton
								onClick={() => onDelete(index)}
								type="button"
							>
								<Icon.CloseThin
									width={11}
									height={11}
									fill={theme.colors.blue}
								/>
							</FileUploadItemButton>
						)}
					</FileUploadItem>
				)
			})}
		</FileUploadListElement>
	)
}

export default FileUploadList
