import { CreateRequestForInformationRequest } from '@graphql/graphql'

import { parseFiles } from '@utils/forms'

export function mapValues(values: any): CreateRequestForInformationRequest {
	return {
		...(values?.companyId ? { companyId: values?.companyId } : {}),
		yourReference: values?.generalReference,
		subject: values?.generalSubject,
		question: values?.generalQuestion,
		files: parseFiles(values?.generalAttachments)
	} as CreateRequestForInformationRequest
}
