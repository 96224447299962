import React, { FC, useCallback, useState, useEffect } from 'react'

import useInterval from '@hooks/useInterval'

import { Text } from './styles'
import { ClockProps } from './types'

const Clock: FC<ClockProps> = ({ color, locale = 'en' }) => {
	const [time, setTime] = useState('')

	const intervalChangeHandler = useCallback(() => {
		setTime(
			Intl.DateTimeFormat(locale, {
				hour: 'numeric',
				minute: 'numeric'
			}).format(new Date())
		)
	}, [locale, setTime])

	useEffect(() => {
		intervalChangeHandler()
	}, [intervalChangeHandler])

	useInterval(intervalChangeHandler, 1000)

	return <Text color={color}>{time}</Text>
}

export default Clock
