export enum SnackbarNotificationTypes {
	SUCCESS = 'success',
	ERROR = 'error',
	WARNING = 'warning'
}

export interface SnackbarProps {
	snackbarType: SnackbarNotificationTypes
	onClose?(): void
	open: boolean
	title: string
}

export interface SnackbarWrapperProps {
	snackbarType: SnackbarNotificationTypes
	isOpen: boolean
}

export interface SnackbarIconProps {
	snackbarType: SnackbarNotificationTypes
}

export interface SnackbarNotificationThemeProps {
	backgroundColor: any
	iconColor: string
}

export type SnackbarNotificationsProps = {
	[key in SnackbarNotificationTypes]: SnackbarNotificationThemeProps
}
