import { Permissions } from '@graphql/graphql'
import { useSession } from 'next-auth/react'

export const usePermissions = (permission: Permissions) => {
	const session = useSession()

	return {
		isLoading: session === undefined || session.status === 'loading',
		isAuthenticated: session?.status === 'authenticated',
		hasAcquired: session?.data?.permissions?.includes(permission) === true
	}
}
