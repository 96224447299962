import { OptionType } from '@damen/ui/lib/cjs/components/Filters/types'
import { Asset } from '@graphql/graphql'

const mapAssetsToOptions = (assets: Asset[]): OptionType[] =>
	assets?.map((asset: Pick<Asset, 'id' | 'name' | 'yardNumber'>) => ({
		label: `${asset.name} - ${asset.yardNumber}`,
		value: asset.id
	})) ?? []

export default mapAssetsToOptions
