import { useEffect } from 'react'

const usePromptOnBeforeUnload = ({ enabled, title }) => {
	useEffect(() => {
		const handler = (event: BeforeUnloadEvent) => {
			event.returnValue = title
			return title
		}

		if (window && enabled) {
			window.addEventListener('beforeunload', handler)
		}

		return () => {
			if (window) {
				window.removeEventListener('beforeunload', handler)
			}
		}
	}, [enabled, title])
}

export default usePromptOnBeforeUnload
