import styled from 'styled-components'
import { theme } from '@damen/ui'

import { hexToRgb } from '@utils/helper'

import { FileUploadListElementProps } from './types'

export const FileUploadListElement = styled.ul<FileUploadListElementProps>`
	list-style: none;
	margin: 0;
	padding: ${({ noTopPadding }) => (noTopPadding ? '0' : '24px 0 0')};
	transition: opacity ${theme.timing.numDefault} ease-in-out;
	will-change: opacity;
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

export const FileUploadItem = styled.li`
	display: flex;
	margin: 0;
	padding: 0 8px 0 0;
	width: 100%;
	align-items: center;
	margin-top: 16px;
	background-color: ${hexToRgb(`${theme.colors.blueIce}CC`, {
		format: 'css'
	})};
	cursor: pointer;
	transition: background-color 250ms ease-in-out;

	&:focus,
	&:hover {
		background-color: ${theme.colors.blueIce};
	}
`

export const FileUploadItemIcon = styled.span`
	display: flex;
	width: 24px;
	height: 24px;
	flex-grow: 0;
	flex-shrink: 0;
	margin: auto 6px auto 10px;
	align-items: center;
	justify-content: center;
`

export const FileUploadItemLabel = styled.span`
	flex-grow: 1;
	flex-shrink: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${theme.colors.marineBlack};
`

export const FileUploadItemButton = styled.button`
	display: flex;
	border: 0;
	width: 48px;
	height: 48px;
	flex-grow: 0;
	flex-shrink: 0;
	background-color: transparent;
	padding: 0;
	cursor: pointer;

	svg {
		margin: auto;
	}
`
