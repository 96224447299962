import React from 'react'
import { Icon, theme } from '@damen/ui'

import {
	isPDF,
	isSpreadsheet,
	isImage,
	isDocument,
	isArchive
} from '../../helpers/fileTypes'

import { FileIconProps } from './types'

const FileIcon: React.FC<FileIconProps> = ({
	type,
	fill = theme.colors.blue
}) => {
	if (isPDF(type)) {
		return <Icon.FilePDF fill={fill} />
	}

	if (isSpreadsheet(type)) {
		return <Icon.FileSpreadsheet fill={fill} />
	}

	if (isImage(type)) {
		return <Icon.FileImage fill={fill} />
	}

	if (isDocument(type)) {
		return <Icon.FileAlt fill={fill} />
	}

	if (isArchive(type)) {
		return <Icon.FileArchive fill={fill} />
	}

	return <Icon.File fill={fill} width={20} height={16} />
}

export default FileIcon
