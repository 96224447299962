import React from 'react'

import ContactButton from '@components/Modals/ContactModal/components/ContactButton'

import { ContactDetailsProps } from './types'
import {
	Body,
	ContactDetailsContainer,
	ContactDetailsImage,
	ContactDetailsName,
	ContactDetailsTitle
} from './styles'

const ContactDetails: React.FC<ContactDetailsProps> = ({
	contact,
	buttonLabel,
	variant
}) => {
	const item = {
		name: contact.name.fullName,
		title: contact.jobTitle,
		email: contact.email,
		phone: contact.phone,
		mobile: contact.mobile,
		image: contact.image,
		labelContact: buttonLabel
	}

	return (
		<ContactDetailsContainer variant={variant}>
			{variant !== 'imageless' && (
				<ContactDetailsImage url={item.image} />
			)}
			<Body>
				<ContactDetailsName>{item.name}</ContactDetailsName>
				<ContactDetailsTitle>{item.title}</ContactDetailsTitle>
				<ContactButton {...item} />
			</Body>
		</ContactDetailsContainer>
	)
}

export default ContactDetails
