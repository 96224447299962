import React, { FC } from 'react'
import { Field } from 'formik'

import Select from '@damen/ui/lib/cjs/components/Form/Select'

import { FieldSelectProps } from './types'

import { SelectAnchor, SelectWrapper } from './styles'

const FieldSelect: FC<FieldSelectProps> = ({
	initialValue,
	label,
	name,
	options,
	readOnly = false,
	placeholder,
	setFieldValue,
	showError = true
}) => {
	return (
		<Field name={name}>
			{({ field, form: { errors } }) => {
				const selectedItem = options?.find(
					(item) => item.value === field.value
				)

				const overridenInitialValue = selectedItem
					? selectedItem.value
					: initialValue

				const error =
					showError &&
					errors[name] !== '' &&
					errors[name] !== undefined
						? `${label} ${errors[name]}`
						: ''

				// We need to set a loading state for the drop down because sometimes our options
				// list is empty and we need to set the initialValue when they're available.
				// We set a different key for a component to ensure a new instance is always generated
				if (options?.length <= 1 && options[0]?.label === undefined) {
					return (
						<>
							<SelectAnchor name={name} />
							<SelectWrapper>
								<Select
									key="loading"
									label={label}
									initialValue={overridenInitialValue}
									options={options}
									onSelect={() => {}}
									readOnly
								/>
							</SelectWrapper>
						</>
					)
				}

				return (
					<>
						<SelectAnchor name={name} />
						<SelectWrapper>
							<Select
								// Key must include overriden value to force rerender/redraw
								key={name + overridenInitialValue}
								label={label}
								initialValue={overridenInitialValue}
								placeholder={placeholder}
								error={error}
								touched={error !== ''}
								options={options}
								onSelect={(event) => {
									setFieldValue(name, event.toString())
								}}
								readOnly={readOnly}
							/>
						</SelectWrapper>
					</>
				)
			}}
		</Field>
	)
}

export default FieldSelect
