export const ASSET = '/my-fleet'
export const ASSET_COMPONENT_DETAIL = '/my-fleet/[id]/[slug]/[slugdetail]'
export const ASSET_PARTREQUESTS_DETAIL = '/my-fleet/[id]/[slug]/[slugdetail]'
export const ASSET_SERVICEREQUESTS = '/my-fleet/[id]/[slug]'
export const ASSET_SERVICEREQUESTS_DETAIL = '/my-fleet/[id]/[slug]/[slugdetail]'
export const ASSET_NEWPARTREQUEST = '/my-fleet/[id]/[slug]/[slugdetail]'

export const DASHBOARD = '/dashboard'
export const HOME = '/'
export const KNOWLEDGE_BASE = '/knowledge-base'
export const MYSETTINGS = '/my-settings'
export const NEW_PARTREQUEST = '/part-requests/new-part-request'
export const NOT_FOUND = '/404'
export const UNAUTHORIZED = '/401'
export const PARTREQUESTS_DETAIL = '/part-requests/[slug]'
export const PARTREQUESTS_OVERVIEW = '/part-requests'
export const SERVICEREQUESTS = '/service-requests'
export const SERVICEREQUESTS_DETAIL = '/service-requests/[slug]'
export const KNOWLEDGEBASE_TECHNICALPAPERS = '/knowledge-base/technnical-papers'
export const KNOWLEDGEBASE_WEBINARS = '/knowledge-base/webinars'
export const KNOWLEDGEBASE_MANUALSANDINSTRUCTIONS =
	'/knowledge-base/manuals-and-instructions'

export const HOME_NAME = 'home'

export const SLUG_SERVICEREQUESTS = 'service-requests'
export const SLUG_COMPONENTDETAIL = 'get-parts'
export const SLUG_PARTREQUESTS = 'part-requests'
