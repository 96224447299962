import React from 'react'
import { Icon, theme } from '@damen/ui'
import Modal from 'react-modal'
import styled from 'styled-components'

import { hexToRgb } from '@utils/helper'
import { DefaultModalProps } from '../types'
import {
	CloseButton,
	Container,
	Content,
	Header,
	Title,
	Wrapper
} from './styles'

const styles: Modal.Styles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 99999
	},
	content: {
		position: 'fixed',
		top: '0',
		left: '0',
		width: '100vw',
		padding: 0,
		border: 0,
		outline: 'none'
	}
}

export const ReactModalAdapter: React.FC<DefaultModalProps> = ({
	className,
	children,
	isOpen,
	maxWidth,
	minHeight,
	onClose,
	transparent,
	title
}) => {
	const wrapperElement = React.useRef<HTMLDivElement>(null)

	const handleClick = (event) => {
		if (!wrapperElement.current) return

		if (
			event.target === wrapperElement.current ||
			wrapperElement.current.contains(event.target)
		)
			return

		onClose()
	}

	return (
		<Modal
			ariaHideApp={false}
			className={`${className}__content`}
			closeTimeoutMS={theme.timing.numDefault}
			contentLabel="Modal"
			isOpen={isOpen}
			overlayClassName={`${className}__overlay`}
			onRequestClose={onClose}
			portalClassName={className}
			role="dialog"
			style={styles}
			shouldCloseOnEsc
			shouldFocusAfterRender
			shouldReturnFocusAfterClose
		>
			<Container
				transparent={transparent}
				onClick={(event) => handleClick(event)}
			>
				<Wrapper
					ref={wrapperElement}
					maxWidth={maxWidth}
					minHeight={minHeight}
					transparent={transparent}
				>
					<Header>
						<CloseButton onClick={onClose}>
							<Icon.CloseThin
								height={16}
								width={16}
								fill={
									transparent
										? theme.colors.white
										: theme.colors.blue
								}
							/>
						</CloseButton>
					</Header>
					{title && <Title>{title}</Title>}
					<Content transparent={transparent}>{children}</Content>
				</Wrapper>
			</Container>
		</Modal>
	)
}

const StyledModal = styled(ReactModalAdapter)`
	&__overlay {
		background-color: transparent;
		transition: background-color 300ms ease;

		&.ReactModal__Overlay--after-open {
			background-color: ${hexToRgb(theme.colors.marineBlack, {
				alpha: 0.75,
				format: 'css'
			})};
			cursor: pointer;
		}

		&.ReactModal__Overlay--before-close {
			background-color: transparent;
		}
	}

	&__content {
		width: 100%;
		height: 100%;
		transform: scale(0.5);
		opacity: 0;
		transition-duration: ${theme.timing.default};
		transition-timing-function: ease-in-out;
		transition-property: transform, opacity;
		outline: none;

		&.ReactModal__Content--after-open {
			transform: scale(1);
			opacity: 1;
		}

		&.ReactModal__Content--before-close {
			transform: scale(0.5);
			opacity: 0;
		}
	}
`

export default StyledModal
