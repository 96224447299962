// TODO import should be import * as Yup from 'yup'
import Yup from '@utils/customYupValidations'
import { ValidationRules } from '@components/UI/Forms/Validation'
import { Validation } from '@components/UI/Forms/Validation/types'
import {
	maxTextAreaMessage,
	maxTextMessage,
	minMessage
} from '@components/UI/Forms/Validation/rules'

export const validationSchema = (
	messages: Validation,
	isCompanyIdRequired: boolean
) => {
	if (!messages) return undefined
	return Yup.object({
		companyId:
			isCompanyIdRequired && Yup.string().required(messages.required),
		generalReference: Yup.string()
			.noWhitespaceAndRequired(messages.required)
			.min(ValidationRules.minimumCharacters, minMessage(messages))
			.max(ValidationRules.maxTextInput, maxTextMessage(messages)),
		generalSubject: Yup.string()
			.noWhitespaceAndRequired(messages.required)
			.min(ValidationRules.minimumCharacters, minMessage(messages))
			.max(ValidationRules.maxTextInput, maxTextMessage(messages)),
		generalQuestion: Yup.string()
			.noWhitespaceAndRequired(messages.required)
			.min(ValidationRules.minimumCharacters, minMessage(messages))
			.max(ValidationRules.maxTextArea, maxTextAreaMessage(messages))
	})
}
