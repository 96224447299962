import styled from 'styled-components'

export const Wrapper = styled.div`
	margin-left: 24px;
`

export const Image = styled.img`
	display: block;
	width: auto;
	height: auto;
	max-width: 110px;
	max-height: 48px;
	object-fit: contain;
	margin: auto 0 auto 32px;
	flex-grow: 0;
	flex-shrink: 0;
`
