import React from 'react'
import { useRouter } from 'next/router'

import MenuButton from '@ui/MenuButton'
import Breadcrumbs from '@ui/Breadcrumbs'

import { useHeaderContext } from '@contexts/HeaderContext'

import { DASHBOARD, HOME } from '@src/constants/Routes'

import NavigationBar from '@components/NavigationBar'

import { Wrapper, Left } from './styles'
import { HeaderProps } from './types'

const Header = ({ breadcrumbs = true }: HeaderProps) => {
	const { asPath } = useRouter()

	const { headerColor, logoSrc } = useHeaderContext()

	const isHomeOrDashboard = [HOME, DASHBOARD].includes(asPath)

	return (
		<Wrapper color={headerColor} lockNavigation={isHomeOrDashboard}>
			<Left>
				<MenuButton color={headerColor === 'blue' ? 'white' : 'blue'} />
				{breadcrumbs && <Breadcrumbs />}
			</Left>
			<NavigationBar
				headerColor={headerColor}
				logoSrc={logoSrc}
				showLogo={isHomeOrDashboard}
			/>
		</Wrapper>
	)
}
export default Header
