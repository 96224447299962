import { useGetGenericTranslationsQuery } from '@graphql/graphql'
import React, { createContext, useContext, useMemo } from 'react'
import { useLocale } from '@hooks/useLocale'
import { MyDamenTranslations, TranslationContext } from './types'

export const TranslationStore = createContext<TranslationContext>(null)

export const useTranslationContext = () => {
	return useContext(TranslationStore)
}

const TranslationProvider = ({ children }) => {
	const { locale } = useLocale()

	const {
		data,
		loading: translationsLoading,
		error: translationsError
	} = useGetGenericTranslationsQuery({
		variables: {
			language: locale
		},
		fetchPolicy: 'cache-first'
	})

	const translations: MyDamenTranslations = useMemo(
		() =>
			data?.translations?.items?.reduce(
				(map, obj) => {
					map[obj.key] = obj.value
					return map
				},
				{
					filterSortBy: null,
					filterClearAll: null,
					filterTitle: null,
					filterShowLabel: null,
					favouritesErrorMessage: null,
					downloadPDFLabel: null,
					defaultErrorMessage: null,
					yes: null,
					no: null,
					edit: null,
					quotationButtonLabel: null,
					quotationAddedLabel: null,
					quotationUpdatedLabel: null,
					quotationRemovedLabel: null,
					quotationGoToRequestLabel: null,
					quotationItemAdd: null,
					quotationItemRemove: null,
					quotationItemRequest: null,
					supportLabel: null,
					buttonSubmit: null,
					stateSaving: null,
					buttonNextStep: null,
					labelCancel: null
				}
			),
		[data]
	)

	const context = useMemo(
		() => ({
			translations,
			loading: translationsLoading,
			error: translationsError
		}),
		[translations, translationsLoading, translationsError]
	)

	return (
		// eslint-disable-next-line react/jsx-no-constructed-context-values
		<TranslationStore.Provider value={context}>
			{children}
		</TranslationStore.Provider>
	)
}

export default TranslationProvider
