import SkeletonLoader from '@components/UI/SkeletonLoader'
import React from 'react'
import { Container, Item, TitleWrapper } from './styles'

const ContactListSkeleton = () => {
	const contacts = 10
	const pageId = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

	return (
		<Container>
			{[...Array(contacts)].map((item, i) => {
				return (
					<Item key={pageId[i]}>
						<TitleWrapper>
							<SkeletonLoader height={16} width="100%" />
						</TitleWrapper>
						<SkeletonLoader height={30} width="50%" />
						<SkeletonLoader height={14} width={70} />
					</Item>
				)
			})}
		</Container>
	)
}

export default ContactListSkeleton
