import React from 'react'

import { SvgProps } from '@damen/ui/lib/cjs/types'

export interface ContactButtonItemProps {
	id: string
	Icon: React.FC<SvgProps>
	label: string
	value: string
	href: string
}

export enum ContactButtonTypes {
	EMAIL = 'email',
	MOBILE = 'mobile',
	PHONE = 'phone'
}
