import {
	ApolloClient,
	ApolloLink,
	HttpLink,
	InMemoryCache
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { HTTP_HEADER_XFUNCTIONSKEY } from '@settings/httpSettings'
import stripEmpty from '@utils/stripEmpty'
import { sha256 } from 'crypto-hash'

const getServerAuthLink = () =>
	setContext((_, { headers }) => {
		const token = process.env.FRONTEND_TOKEN
		return {
			headers: {
				authorization: `Bearer ${token}`,
				...headers
			}
		}
	})

const getClientAuthLink = () => {
	return setContext((_, { headers }) => {
		return {
			headers: {
				...headers
			}
		}
	})
}

const getErrorLink = () =>
	onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors) {
			graphQLErrors.map(({ message, locations, path }) =>
				// eslint-disable-next-line no-console
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
						locations
					)}, Path: ${path}`
				)
			)
		}

		if (networkError) {
			// eslint-disable-next-line no-console
			console.log(`[Network error]: ${networkError}`)
		}
	})

const getServerPersistedQueryLink = () =>
	createPersistedQueryLink({
		sha256
	})

const getClientPersistedQueryLink = () =>
	createPersistedQueryLink({
		sha256,
		useGETForHashedQueries: true
	})

const getServerHttpLink = () =>
	new BatchHttpLink({
		uri: process.env.API_ENDPOINT_GRAPHQL,
		headers: stripEmpty({
			[HTTP_HEADER_XFUNCTIONSKEY]:
				process.env.API_AZUREFUNCTION_XFUNCTIONSKEY
		}),
		includeUnusedVariables: true
	})

const getClientHttpLink = () =>
	new HttpLink({
		uri: process.env.NEXT_PUBLIC_API_ENDPOINT_GRAPHQL
	})

export const createApolloClient = () => {
	const isServer = typeof window === 'undefined'

	const apolloLink = ApolloLink.from(
		[
			isServer ? getServerAuthLink() : getClientAuthLink(),
			getErrorLink(),
			isServer
				? getServerPersistedQueryLink()
				: getClientPersistedQueryLink(),
			isServer ? getServerHttpLink() : getClientHttpLink()
		].filter((x) => x)
	)

	const cache: InMemoryCache = new InMemoryCache({
		typePolicies: {
			Facet: {
				// Facet has an ID field but should never be normalized
				keyFields: false
			}
		}
	})

	return new ApolloClient({
		ssrMode: !isServer,
		link: apolloLink,
		cache,
		assumeImmutableResults: true,
		credentials: 'same-origin',
		queryDeduplication: true
	})
}
