import { useLayoutEffect, useState } from 'react'

export interface WindowSizeResponse {
	width?: number
	height?: number
}

const useWindowSize = (): WindowSizeResponse => {
	const isClient = typeof window === 'object'

	const [size, setSize] = useState({
		width: isClient ? window.innerWidth : undefined,
		height: isClient ? window.innerHeight : undefined
	})

	useLayoutEffect(() => {
		const handleResize = () => {
			setSize({
				width: window.innerWidth,
				height: window.innerHeight
			})
		}

		handleResize()
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return size
}

export default useWindowSize
