// The NON_DAMEN_VESSEL is an existing IFS assetId
// This is used to couple the non-damen vessel to IFS
export const NON_DAMEN_VESSEL = '11476-144'
export const SUPPORT = 'support'

export const YES = 'yes'
export const NO = 'no'
export const OTHER = 'other'

export const FORM_OPTION_CHOOSE_DATE = 'choose-date'
export const FORM_OPTION_AS_SOON_AS_POSSIBLE = 'as-soon-as-possible'

export const CASETYPE_WARRANTY_OPTION_ID = 'warranty'

export const OPTION_WORK = 'work'
export const OPTION_HOME = 'home'
export const OPTION_OTHER = 'other'
export const OPTION_MOBILE = 'mobile'
export const OPTION_FAX = 'fax'
export const OPTION_PAGER = 'pager'

export const HIGH = 'high'
export const NORMAL = 'normal'
export const LOW = 'low'
