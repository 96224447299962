import React, {
	createContext,
	useState,
	useMemo,
	useCallback,
	useContext,
	ReactNode,
	useEffect
} from 'react'
import { DxpFormStepper, FormState } from '@components/UI/Forms/types'

import { UserAccountCompany } from '@graphql/graphql'
import { appendVessel } from '@contexts/Helpers/newRequestContexthelpers'
import { validationSchema } from './Validation'
import { initialValues } from './initialValues'

interface DxpFormRFI extends DxpFormStepper {
	companies?: UserAccountCompany[]
}

export const NewRequestForInformationStore = createContext<DxpFormRFI>(null)

export const useNewRequestForInformationContext = () =>
	useContext(NewRequestForInformationStore)

export type NewRequestForInformationContextProps = {
	children: ReactNode
	initialValue?: {
		initialVesselId: string
	}
}

const NewRequestForInformationProvider = ({
	children,
	initialValue
}: NewRequestForInformationContextProps) => {
	// Contexts
	const { initialVesselId } = initialValue ?? {}
	const [title, setTitle] = useState('')
	const [state, setState] = useState<FormState>(FormState.inProgress)
	const [values, setValues] = useState(
		appendVessel(initialValues, initialValue?.initialVesselId)
	)
	useEffect(() => {
		setValues(appendVessel(initialValues, initialVesselId))
	}, [initialVesselId])

	const formComplete = useCallback(() => {
		setState(FormState.complete)
	}, [])

	const resetForm = useCallback(() => {
		setState(FormState.inProgress)
		setValues(appendVessel(initialValues, initialValue?.initialVesselId))
	}, [initialValue, setValues])

	const context = useMemo(
		() => ({
			formComplete,
			title,
			initialValues: values,
			setTitle,
			validationSchema,
			resetForm,
			setState,
			state
		}),
		[formComplete, title, values, resetForm, state]
	)

	return (
		<NewRequestForInformationStore.Provider value={context}>
			{children}
		</NewRequestForInformationStore.Provider>
	)
}

export default NewRequestForInformationProvider
