import { DamenContact } from '@graphql/graphql'

export enum ContactDetailsVariant {
	DEFAULT = 'default',
	IMAGELESS = 'imageless'
}

export interface ContactDetailsProps {
	contact: DamenContact
	buttonLabel: string
	variant?: ContactDetailsVariant
}
