import React, { FC, useCallback } from 'react'
import { Field } from 'formik'

import FileUpload from '@components/UI/FileUpload'
import { FormDescription, FormLabel } from '@components/UI/Forms/FormUI'

import { FieldFileUploadProps } from './types'

const FieldFileUpload: FC<FieldFileUploadProps> = ({
	description = '',
	label = '',
	name,
	mode,
	setFieldValue,
	showError = true,
	...props
}) => {
	const onFileSelect = useCallback(
		(currentFiles) => setFieldValue(name, currentFiles),
		[name, setFieldValue]
	)
	return (
		<Field name={name}>
			{({
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				_field,
				form: { errors }
			}) => {
				return (
					<>
						<FormLabel htmlFor={name}>{label}</FormLabel>
						{description && (
							<FormDescription>{description}</FormDescription>
						)}
						<FileUpload.Default
							key={name}
							description={description}
							error={
								showError &&
								errors &&
								errors[name] !== '' &&
								errors[name] !== undefined
									? `${label} ${errors[name]}`
									: ''
							}
							// Current setup has this in an infinite loop
							initialValue={_field?.value}
							mode={mode}
							onFileSelect={onFileSelect}
							{...props}
						/>
					</>
				)
			}}
		</Field>
	)
}

export default FieldFileUpload
