import { Languages } from '@graphql/graphql'
import { PreferencesRequestValues } from '@components/Modals/MySettingsModal/components/Preferences/types'

const languageMapping = {
	en: Languages.English,
	'es-es': Languages.Spanish,
	'fr-fr': Languages.French
}

export function mapLanguages(values: PreferencesRequestValues): Languages {
	return languageMapping[values?.language] ?? Languages.English
}

export function mapLanguage(value: string): Languages {
	return languageMapping[value] ?? Languages.English
}
