import styled from 'styled-components'
import { motion } from 'framer-motion'

import { theme } from '@damen/ui'

import { ACTION } from '@src/constants/zIndexes'

import { hexToRgb } from '@utils/helper'
import Link from 'next/link'

export const Wrapper = styled.div`
	display: inline-block;
	position: relative;
	padding-right: 20px;

	> button {
		font-size: 14px;
		line-height: 14px;
	}
`

export const List = styled(motion.ul)`
	position: absolute;
	left: 0;
	top: 30px;
	min-width: 278px;
	max-width: 291px;
	display: block;
	padding: 0;
	box-shadow: 10px 20px 40px 0
		${hexToRgb(theme.colors.marineBlack, {
			alpha: 0.08,
			format: 'css'
		})};
	background-color: ${theme.colors.white};
	z-index: ${ACTION};
`

export const Item = styled.li``

export const ItemButton = styled(Link)`
	display: flex;
	width: 100%;
	height: 68px;
	border: 0;
	background-color: transparent;
	padding: 0 16px;
	cursor: pointer;
	transition: background ${theme.timing.fast} ease-in-out;
	position: relative;
	align-items: center;
	text-decoration: none;

	&::before {
		content: '';
		display: block;
		height: 1px;
		background-color: ${hexToRgb(`${theme.colors.grey}33`, {
			format: 'css'
		})};
		position: absolute;
		left: 16px;
		right: 16px;
		bottom: 0;
	}

	&:last-of-type {
		&::before {
			display: none;
		}
	}

	svg {
		flex-grow: 0;
		flex-shrink: 0;
		margin: auto 12px auto 0;
	}

	&:hover,
	&focus {
		background-color: ${theme.colors.blueIce};
		text-decoration: underline;
		text-underline-offset: 4px;
	}
`

export const ItemButtonLabel = styled.span`
	margin: auto auto auto 0;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.44px;
	color: ${theme.colors.blue};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`
