import {
	useAppInsightsContext,
	withAITracking
} from '@microsoft/applicationinsights-react-js'
import { createApplicationInsights } from '@utils/appInsights'
import { useOnRouteChangeComplete } from '@hooks/useOnRouteChange'

export const ApplicationInsightsTracker = ({ children }) => {
	const appInsights = useAppInsightsContext()

	useOnRouteChangeComplete((url) => {
		appInsights.trackPageView({ name: url })
	})

	return children
}

export default withAITracking(
	createApplicationInsights().reactPlugin,
	ApplicationInsightsTracker
)
