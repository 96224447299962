import {
	ASSET,
	SLUG_COMPONENTDETAIL,
	SLUG_PARTREQUESTS,
	SLUG_SERVICEREQUESTS
} from '@src/constants/Routes'

// Replace the url values with the dynamic route,
// because the pathname refers to the actual file path and not the slug value
// https://nextjs.org/docs/app/building-your-application/routing/dynamic-routes
export const getPathNameFromUrl = (url: string): string => {
	// Check if the url is from my-fleet, because we don't want to replace the slugs on the root level
	if (url?.startsWith(ASSET)) {
		return url
			?.replace('--assetId--', '[id]')
			.replace(`${SLUG_SERVICEREQUESTS}`, '[slug]')
			.replace(`${SLUG_COMPONENTDETAIL}`, '[slug]')
			.replace(`${SLUG_PARTREQUESTS}`, '[slug]')
	}
	return url
}
