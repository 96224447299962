import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { Icon, theme } from '@damen/ui'

import { LOADER } from '@src/constants/zIndexes'

const Loading: React.FC = forwardRef<HTMLDivElement, null>(
	(props: Record<any, any> | null, ref) => (
		<Foreground ref={ref} {...props}>
			<div>
				<Icon.LogoDamen fill={theme.colors.white} />
			</div>
			<FgInner />
		</Foreground>
	)
)

const Foreground = styled(motion.div)`
	display: flex;
	z-index: ${LOADER};
	position: absolute;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	transform: scale(1.5);
	top: 0;
	left: 0;
	background: ${theme.colors.blue};

	svg,
	g,
	path {
		fill: ${theme.colors.white};
	}
`

const FgInner = styled.div`
	position: absolute;
	margin: 0;
	padding: 0;
	border-radius: 0%;
	box-shadow: 0 0 0 300px ${theme.colors.blue};
	top: 0;
	left: 50%;
	transform: translate(-50%, -110%);
	width: 200%;
	height: 100%;
`

export default motion(Loading)
