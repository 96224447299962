import React from 'react'
import { Icon, theme } from '@damen/ui'

import {
	SnackbarNotificationTypes,
	SnackbarProps
} from '@components/UI/Snackbar/types'

import { Wrapper, SnackbarIcon, Body, CloseButton } from './styles'

const Snackbar: React.FC<SnackbarProps> = ({
	snackbarType,
	open,
	onClose,
	title
}) => (
	<Wrapper isOpen={open} snackbarType={snackbarType}>
		<SnackbarIcon snackbarType={snackbarType}>
			{snackbarType === SnackbarNotificationTypes.SUCCESS && (
				<Icon.Check
					fill={theme.colors.greenLight}
					width={15}
					height={10}
				/>
			)}
			{snackbarType === SnackbarNotificationTypes.ERROR && (
				<Icon.Exclamation
					fill={theme.colors.redLight}
					width={18}
					height={18}
				/>
			)}
			{snackbarType === SnackbarNotificationTypes.WARNING && (
				<Icon.Info
					// lightYellow color that is not available in the theme
					fill="#f5f1d4"
					width={18}
					height={18}
				/>
			)}
		</SnackbarIcon>
		<Body>{title}</Body>
		<CloseButton onClick={onClose}>
			<Icon.CloseThin width={18} height={18} />
		</CloseButton>
	</Wrapper>
)

export default Snackbar
