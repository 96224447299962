import { SparePart } from '@graphql/graphql'
import { MultilinkStoryblok } from '@graphql/storyblokcomponents'

export enum NewPartRequestNotificatitonStatus {
	ADDED = 'added',
	UPDATED = 'updated',
	REMOVED = 'removed'
}

export interface NewPartRequestRowEntry {
	id: string
	quantity: number
	part: SparePart
	vesselId: string
}

export interface NewPartsTranslations {
	labelDescription: string
	labelNumber: string
	emptyTitle: string
	emptyContent: string
	emptyButtonLink: MultilinkStoryblok
	emptyButtonLabel: string
	errorTitle: string
	errorContent: string
	errorButtonContent: string
	searchNotFoundTitle: string
	searchNotFoundContent: string
	searchNotFoundButtonContent: string
	modalTitle: string
	modalSpecifications: string
	modalSerialNumber: string
	modalManufacturingPartNumber: string
	modalDamenPartNumber: string
	modalDeliveryTime: string
	modalNetPrice: string
	modalSystemId: string
	modalSystemName: string
	modalDocuments: string
	modalRequestQuotationButton: string
	system: string
	subSystem: string
	subSubSystem: string
	labelRemoveQuotation: string
	labelRequestQuotation: string
}

export interface NewPartRequestStoreProps {
	blok: NewPartsTranslations
	clearItems: () => void
	count: number
	items: NewPartRequestRowEntry[]
	status: NewPartRequestNotificatitonStatus

	addItem: (item: SparePart, quantity: number) => void
	isItemInList: (item: SparePart) => boolean
	removeItem: (item: SparePart) => void
	setBlok: (blok: NewPartsTranslations) => void
	toggleItem: (item: SparePart) => void
	updateQuantity: (item: SparePart, quantity: number) => void
}
