import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Dropzone, { DropzoneRef, ErrorCode, FileRejection } from 'react-dropzone'
import { Icon, theme } from '@damen/ui'

import {
	FileUploadFileReference,
	FileUploadProps,
	Mode
} from '@components/UI/FileUpload/types'

import Loader from '@components/UI/Loader'
import { postFile } from '@lib/postFile'
import { FileUploadStoryblok } from '@graphql/storyblokcomponents'
import { getFileExtension } from '@utils/getFileExtension'
import FileUploadList from './FileUploadList'

import {
	FileUploadHotspot,
	FileUploadIcon,
	FileUploadInput,
	FileUploadPlaceholder,
	FileUploadError
} from './styles'
import { FileState, FileUploadStates } from './types'

const getAcceptedFiles = (
	acceptedFilesData: FileUploadStoryblok['acceptedFileTypes']
): Record<string, string[]> => {
	if (acceptedFilesData === undefined) {
		return {}
	}

	return acceptedFilesData.reduce((result, x) => {
		// eslint-disable-next-line no-param-reassign
		result[x.mimeType] = result[x.mimeType] ?? []

		result[x.mimeType].push(x.fileExtension)
		return result
	}, {} as Record<string, string[]>)
}

const uniqueFiles = (files: File[], existingFiles: File[]) => {
	const getKey = (file: File) => `${file.name}|${file.lastModified}`

	const fileUniques = new Set<string>(existingFiles.map(getKey))
	const result: File[] = []
	files.forEach((file) => {
		const key = getKey(file)
		if (fileUniques.has(key)) {
			return
		}
		fileUniques.add(key)
		result.push(file)
	})

	return result
}

const FileUpload: React.FC<FileUploadProps> = ({
	acceptedFileTypes,
	error,
	errorFileTooBig,
	errorFileUpload,
	errorTooManyFiles,
	errorWrongFileType,
	initialValue = [],
	maxFileSizeMb = 5,
	maxNumberFiles = 5,
	mode = Mode.READ_AND_WRITE,
	noTopPadding = false,
	onFileSelect,
	onFileUploading,
	onFileUploadComplete,
	placeholder,
	uploading
}) => {
	const dropzoneRef = React.createRef<DropzoneRef>()
	// Component state
	const [fileList, setFileList] = useState(initialValue)
	const [{ uploadState, fileError }, setUploadState] = useState({
		uploadState: FileUploadStates.INITIAL,
		fileError: undefined
	})

	const deleteFileItemHandler = useCallback((indexToRemove) => {
		setFileList((prev) => {
			if (prev.length === 1) {
				return []
			}
			const newList = [...prev].filter(
				(_, index) => index !== indexToRemove
			)
			return newList
		})
	}, [])

	const errorMessageLookup = useMemo(() => {
		return {
			[ErrorCode.FileTooLarge]: errorFileTooBig,
			[ErrorCode.TooManyFiles]: errorTooManyFiles,
			[ErrorCode.FileInvalidType]: errorWrongFileType,
			generic: errorFileUpload
		}
	}, [
		errorFileTooBig,
		errorFileUpload,
		errorTooManyFiles,
		errorWrongFileType
	])

	const accept = useMemo(
		() => getAcceptedFiles(acceptedFileTypes),
		[acceptedFileTypes]
	)

	const dropAcceptedHandler = useCallback(
		(newAcceptedFiles: File[]) => {
			setUploadState({
				uploadState: FileUploadStates.UPLOADING,
				fileError: undefined
			})

			const newAcceptedFilesUnique = uniqueFiles(
				newAcceptedFiles,
				fileList
			)

			const tasks = newAcceptedFilesUnique.map(async (file) => {
				// Check if file has 'url' property
				if (!('url' in file)) {
					const result = await postFile(file)

					Object.assign(file, result)
					return file as FileUploadFileReference
				}
				return null
			})

			Promise.all(tasks)
				.then((files) => {
					setFileList((currentFiles) => {
						return [
							...currentFiles.filter((x) => x != null),
							...files
						]
					})
					setUploadState({
						uploadState: FileUploadStates.COMPLETE,
						fileError: undefined
					})
				})
				.catch((err) => {
					if (err.message === ErrorCode.FileInvalidType) {
						setUploadState({
							uploadState: FileUploadStates.WARNING,
							fileError:
								errorMessageLookup[ErrorCode.FileInvalidType]
						})
						return
					}
					setUploadState({
						uploadState: FileUploadStates.ERROR,
						fileError: errorMessageLookup.generic
					})
				})
		},
		[errorMessageLookup, fileList]
	)

	const validatorHandler = useCallback(
		<T extends File>(
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			file: T
		) => {
			const fileType = file.type
			const fileExtension = getFileExtension(file.name)

			if (
				accept[fileType] === undefined ||
				!accept[fileType].includes(fileExtension)
			) {
				console.warn(
					`file type ${fileType} is not valid in combination with ${fileExtension}`
				)
				return {
					code: ErrorCode.FileInvalidType,
					message: errorWrongFileType || '[Wrong file type]'
				}
			}
			if (fileList.length === Number(maxNumberFiles)) {
				return {
					code: ErrorCode.TooManyFiles,
					message: errorTooManyFiles || '[Too many files]'
				}
			}
			return null
		},
		[
			accept,
			fileList.length,
			maxNumberFiles,
			errorWrongFileType,
			errorTooManyFiles
		]
	)

	const dropRejectedHandler = useCallback(
		(fileRejections: FileRejection[]) => {
			if (fileRejections?.length) {
				setUploadState({
					uploadState: FileUploadStates.WARNING,
					fileError:
						errorMessageLookup[fileRejections[0].errors[0].code]
				})
			}
		},
		[errorMessageLookup]
	)

	const onError = useCallback((_error: Error) => {
		console.debug('onerror', _error)
	}, [])

	useEffect(() => {
		if (uploadState === FileUploadStates.UPLOADING) {
			onFileUploading?.()
		} else if (
			uploadState === FileUploadStates.COMPLETE ||
			uploadState === FileUploadStates.WARNING
		) {
			onFileUploadComplete?.(FileState.SUCCES)
		} else if (uploadState === FileUploadStates.ERROR) {
			onFileUploadComplete?.(FileState.ERROR)
		}
	}, [onFileUploadComplete, onFileUploading, uploadState])

	// Update "real" form
	useEffect(() => {
		onFileSelect?.(fileList)
	}, [fileList, onFileSelect])

	const hasError = error !== ''
	const maxSizeInBytes = maxFileSizeMb * 1024 * 1024
	return (
		<>
			{mode === Mode.READ_AND_WRITE && (
				<Dropzone
					ref={dropzoneRef}
					accept={accept}
					onDropAccepted={dropAcceptedHandler}
					onDropRejected={dropRejectedHandler}
					maxSize={maxSizeInBytes}
					maxFiles={Number(maxNumberFiles)}
					onError={onError}
					// Set multiple to false because it is currently not possible to handle multiple error messages
					// In that case the user won't know which file is faulty / not uploaded
					multiple={false}
					validator={validatorHandler}
				>
					{({ getRootProps, getInputProps }) => {
						const isUploading =
							uploadState === FileUploadStates.UPLOADING
						const additionalProps = isUploading
							? {}
							: { ...getRootProps() }

						return (
							<FileUploadHotspot
								hasError={hasError}
								{...additionalProps}
							>
								<FileUploadIcon>
									{isUploading ? (
										<Loader inline />
									) : (
										<Icon.FileUpload
											fill={theme.colors.blue}
											width={18}
											height={24}
										/>
									)}
								</FileUploadIcon>
								<FileUploadPlaceholder>
									{isUploading ? uploading : placeholder}
								</FileUploadPlaceholder>

								{/* note this is not the input that is submitted */}
								<FileUploadInput {...getInputProps()} />
							</FileUploadHotspot>
						)
					}}
				</Dropzone>
			)}
			{fileList?.length > 0 && (
				<FileUploadList
					disabled={uploadState === FileUploadStates.UPLOADING}
					items={fileList as FileUploadFileReference[]}
					mode={mode}
					onDelete={deleteFileItemHandler}
					noTopPadding={noTopPadding}
				/>
			)}
			{fileError && <FileUploadError>{fileError}</FileUploadError>}
		</>
	)
}

export default FileUpload
